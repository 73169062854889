import React, { Component } from 'react';
import styled from '@emotion/styled';

import Topbar from '../../../common/component/topbar/Topbar';
import Sidebar from '../../../common/componentV2/sidebar/Sidebar';
import { connect } from 'react-redux';
import { GET, POST } from '../../../services/HttpRequests';
import { ThemeContext } from '../../../providers/customeThemeProvider';
import Popup from '../../../common/component/popups/popups';
import Close from '../../../assets/images/popupClose.png';
import DataTable from '../../../common/component/table/DataTable';
import TabSearchBar from '../../../common/component/TabSearchbar/TabSearchBarComponent';
import Delete from '../../../assets/images/deleteWRedBackground.png';
import { EditIcon } from '../../../assets/Icons/Icons';
import { v4 as uuidv4 } from 'uuid';
import InfoRed from '../../../assets/images/infoRed.png';
import { toast } from 'react-toastify';
import moment from 'moment';
import {
  ADMIN_ORGANIZATIONAL_HOLIDAY_ADD_BUTTON,
  ADMIN_ORGANIZATIONAL_HOLIDAY_DELETE_BUTTON,
  ADMIN_ORGANIZATIONAL_HOUR_HOLIDAY_SAVE_BUTTON,
  ADMIN_ORGANIZATIONAL_DAY_BUTTON,
  ADMIN_ORGANIZATIONAL_HOUR_BUTTON,
} from '../../../common/posthog/events';
import { logPostHogEvent } from '../../../common/posthog/posthog';

const orgUrl = process.env.REACT_APP_ORGANIZATION_API;
const role = localStorage.getItem('doosra-biz-user-role');
const organisationId = localStorage.getItem('doosra-biz-organisation-id');
const planAvailable = localStorage.getItem('is_plan_available');
let isFreeTrail = localStorage.getItem('is_free_trial_enabled');
isFreeTrail = isFreeTrail ? (isFreeTrail === 'true' ? true : false) : false;
const showPaymentHistory = localStorage.getItem('show_payment_history');

class OffHoursPage extends Component {
  constructor() {
    super();
    this.state = {
      error: '',
      holidayError: '',
      userRole: role,
      popupOpen: false,
      holidayList: [],
      holidayName: null,
      holidayDate: null,
      holidayId: uuidv4(),
      holidayAudio: null,
      shiftDays: [],
      shiftStartTime: null,
      shiftEndTime: null,
      shiftAudio: null,
      popupTitle: 'Add',
      popupBtn: 'SAVE',
    };
  }

  static contextType = ThemeContext;
  componentDidMount() {
    this.setState({
      userRole: role,
      popupOpen: false,
      holidayList: [],
      holidayName: null,
      holidayDate: null,
      holidayId: uuidv4(),
      holidayAudio: null,
      shiftDays: [],
      shiftStartTime: null,
      shiftEndTime: null,
      shiftAudio: null,
      popupTitle: 'Add',
      popupBtn: 'SAVE',
    });
    this.fetchHolidayData();
  }

  fetchHolidayData = async () => {
    try {
      this.setState({ error: '' });
      const res = await GET(`${orgUrl}v1/organisations/holiday`, {
        organisationId,
      });
      const holidayListdata = res?.data?.response?.holidayList || [];
      if (holidayListdata) {
        const {
          workingDays,
          startTime,
          endTime,
          dayOffAudio,
          holidayAudio,
          holidays,
        } = holidayListdata;
        this.setState({
          shiftDays: workingDays,
          shiftStartTime: startTime,
          shiftEndTime: endTime,
          shiftAudio: dayOffAudio,
          holidayList: holidays,
          holidayAudio,
        });
      }
    } catch (err) {
      this.setState({
        shiftDays: [],
        shiftStartTime: null,
        shiftEndTime: null,
        shiftAudio: null,
        holidayList: [],
        holidayAudio: null,
      });
    }
  };
  /** Save entire Data with Sigble API */
  saveData = async () => {
    logPostHogEvent(ADMIN_ORGANIZATIONAL_HOUR_HOLIDAY_SAVE_BUTTON);
    let {
      holidayAudio,
      shiftDays,
      shiftStartTime,
      shiftEndTime,
      shiftAudio,
      holidayList,
    } = this.state;

    if (
      (shiftDays && shiftDays.length > 0) ||
      (shiftStartTime && shiftStartTime != '') ||
      (shiftEndTime && shiftEndTime != '')
    ) {
      if (!shiftDays || shiftDays.length <= 0) {
        this.setState({ error: 'weekly working days Required !' });
        return;
      }
      if (!shiftStartTime) {
        this.setState({ error: 'start of day (time) Required !' });
        return;
      }
      if (!shiftEndTime) {
        this.setState({ error: 'end of day (time) Required !' });
        return;
      }
      if (!shiftAudio) {
        this.setState({ error: 'day off audio Required !' });
        return;
      }
    }

    if (holidayList && holidayList.length > 0) {
      for (let i = 0; i < holidayList.length; i++) {
        const { name, date } = holidayList[i];
        if (!name) {
          this.setState({ error: 'holiday name Required !' });
          return;
        }
        if (!date) {
          this.setState({ error: 'holiday date Required !' });
          return;
        }
      }
      if (!holidayAudio) {
        this.setState({ error: 'holiday audio Required !' });
        return;
      }
    }

    let formData = new FormData();
    formData.append('organisationId', organisationId);
    formData.append('workingDays', JSON.stringify(shiftDays || []));
    formData.append('startTime', shiftStartTime);
    formData.append('endTime', shiftEndTime);
    formData.append('dayOffAudio', shiftAudio);
    formData.append('holidays', JSON.stringify(holidayList || []));
    formData.append('holidayAudio', holidayAudio);

    await POST(`${orgUrl}v1/organisations/holiday`, formData)
      .then((res) => {
        return res.data.response;
      })
      .then((res) => {
        toast.success(res?.message || 'Data Saved Successfully');
        this.fetchHolidayData();
      })
      .catch((err) => {
        console.log('SaveData:error:', {
          err,
          reason: err?.response?.data?.error?.reason,
        });
        toast.error(err?.response?.data?.error?.reason || 'Data Not Saved');
      });
  };

  addHolidayData = (holidayId) => {
    logPostHogEvent(ADMIN_ORGANIZATIONAL_HOLIDAY_ADD_BUTTON);
    const { holidayName, holidayDate } = this.state;
    if (!holidayName) {
      this.setState({ holidayError: 'Holiday Name Required !' });
      return;
    }
    if (!holidayDate) {
      this.setState({ holidayError: 'Holiday Date Required !' });
      return;
    }
    this.setState({ holidayError: '' });
    const obj = { name: holidayName, date: holidayDate, id: holidayId };
    const array = this.state.holidayList || [];
    if (this.state.popupBtn === 'EDIT') {
      array.map((obj) => {
        if (obj.id === holidayId) {
          obj.name = holidayName;
          obj.date = holidayDate;
        }
        return obj;
      });
    } else {
      array.push(obj);
    }
    this.setState({ holidayList: array });
    this.closePopup();
  };

  deleteHoliday = (id) => {
    logPostHogEvent(ADMIN_ORGANIZATIONAL_HOLIDAY_DELETE_BUTTON);
    const dbArray = this.state.holidayList;
    const resultArray = dbArray.filter((obj) => obj.id !== id);
    this.setState({ holidayList: resultArray });
  };

  closePopup = () => {
    this.setState({ holidayName: null });
    this.setState({ holidayDate: null });
    this.setState({ holidayId: uuidv4() });
    this.setState({ popupOpen: false });
  };

  addShiftDays = (value) => {
    logPostHogEvent(ADMIN_ORGANIZATIONAL_DAY_BUTTON);
    let shiftDaysList = this.state.shiftDays || [];
    if (shiftDaysList.includes(value)) {
      shiftDaysList.splice(shiftDaysList.indexOf(value), 1);
    } else {
      shiftDaysList.push(value);
    }
    this.setState({ shiftDays: shiftDaysList });
  };

  removeShiftTimings = () => {
    this.setState({ shiftStartTime: '' });
    this.setState({ shiftEndTime: '' });
    this.setState({ shiftDays: [] });
  };

  render() {
    let holidayData;

    const DayName = (props) => {
      let shiftDaysList = this.state.shiftDays || [];
      let bgcolor = null;
      let txcolor = null;
      if (shiftDaysList.includes(props.value)) {
        bgcolor = '#0a82ff';
        txcolor = '#fff';
      }
      return (
        <div
          className="outer-circle"
          style={{ background: bgcolor, color: txcolor }}
          onClick={(e) => {
            this.addShiftDays(props.value);
          }}
        >
          <div>{props.name}</div>
        </div>
      );
    };

    const holidayAudioChange = (e) => {
      const file = e.target.files[0];
      if (file && file.size <= 1 * 1024 * 1024) {
        this.setState({ holidayAudio: file });
        this.setState({ error: null });
      } else {
        this.setState({ holidayAudio: null });
        this.setState({
          error:
            'Please upload holiday audio file within the size limit (1 MB).',
        });
      }
    };

    const shiftAudioChange = (e) => {
      const file = e.target.files[0];
      if (file && file.size <= 1 * 1024 * 1024) {
        this.setState({ shiftAudio: file });
        this.setState({ error: null });
      } else {
        this.setState({ shiftAudio: null });
        this.setState({
          error:
            'Please upload day off audio file within the size limit (1 MB).',
        });
      }
    };

    const holidayPopup = (
      <Popup
        open={this.state.popupOpen}
        closeOnDocumentClick={false}
        closeOnEscape={false}
      >
        <div className="PopupContainer">
          <div className="PopupHeaderContainer">
            <p>{this.state.popupTitle} a holiday</p>
            <img
              alt=""
              className="PopupClose"
              src={Close}
              onClick={this.closePopup}
            />
          </div>
          <div className="PopupInputContainer">
            <label className="PopupInputLabel">Holiday Name</label>
            <input
              className="PopupInput"
              id="emp_id"
              value={this.state.holidayName}
              onChange={(e) => this.setState({ holidayName: e.target.value })}
            />
          </div>
          <div className="PopupInputContainer">
            <label className="PopupInputLabel">Holiday Date</label>
            <input
              className="PopupInput"
              id="date"
              type="date"
              value={this.state.holidayDate}
              onChange={(e) => this.setState({ holidayDate: e.target.value })}
            />
          </div>

          <div className="ErrorContainer ErrorContainerMargin">
            {this.state.holidayError ? (
              <React.Fragment>
                <img
                  alt=""
                  src={InfoRed}
                  style={{
                    paddingRight: '5px',
                    position: 'relative',
                    top: '-2px',
                  }}
                />
                {this.state.holidayError}
              </React.Fragment>
            ) : null}
          </div>

          <div className="PopupBody">
            <DataTable.Table
              style={{ width: '100%', marginTop: 15, marginBottom: 5 }}
            >
              <DataTable.Body>
                <button
                  className="ButtonFullWidth"
                  onClick={() => this.addHolidayData(this.state.holidayId)}
                >
                  {this.state.popupBtn}
                </button>
                <button
                  className="ButtonCancelFullWidth"
                  onClick={this.closePopup}
                >
                  Close
                </button>
              </DataTable.Body>
            </DataTable.Table>
          </div>
        </div>
      </Popup>
    );
    /**Holidat List Data */
    if (this.state.holidayList?.length > 0) {
      holidayData = this.state?.holidayList.map((item) => (
        <DataTable.Row>
          <DataTable.Cell>
            {moment(item.date).format('DD/MM/YYYY')}
          </DataTable.Cell>
          <DataTable.Cell>{item.name}</DataTable.Cell>
          <DataTable.Cell style={{ width: '20%' }}>
            <EditIcon
              onClick={() => {
                this.setState({ holidayName: item.name });
                this.setState({
                  holidayDate: moment(item.date).format('YYYY-MM-DD'),
                });
                this.setState({ holidayId: item.id });
                this.setState({ popupOpen: true });
                this.setState({ popupTitle: 'Edit' });
                this.setState({ popupBtn: 'EDIT' });
              }}
            />
            <img
              alt=""
              style={{ marginLeft: 10, cursor: 'pointer' }}
              onClick={(e) => this.deleteHoliday(item.id)}
              src={Delete}
            />
          </DataTable.Cell>
        </DataTable.Row>
      ));
    }

    return (
      <div className={`${this.props.className}`}>
        <Topbar />
        <div style={{ display: 'flex', height: 'calc(100vh - 72px)' }}>
          <Sidebar />
          <div className="ContentContainer">
            {/* {role !== 'USER' && !isFreeTrail && <TabSearchBar tabs={tabs} />} */}
            <div
              style={{
                marginTop: 3,
                padding: 20,
                width: '80%',
                paddingLeft: 20,
              }}
            >
              <div
                className="AuthSubHeader"
                style={{ fontSize: 18, fontWeight: 600 }}
              >
                Set global Organisational work hours, holidays and upload audio
                that plans during these off-days.
              </div>
              <div
                className="AuthSubHeader"
                style={{ fontSize: 18, paddingTop: 20 }}
              >
                Note: Working day and holiday settings apply to all
                caller-groups and agents in your organisation.
              </div>
              <div className="BottomBorder" />
              <>
                <div className="HeadText">Set weekly working days</div>
                <div className="CaptionText">
                  Set the days duringthe week whenyour organisation operates
                </div>
                <div style={{ display: 'flex', marginTop: 30 }}>
                  <DayName name="M" value="MONDAY"></DayName>
                  <DayName name="T" value="TUESDAY"></DayName>
                  <DayName name="W" value="WEDNESDAY"></DayName>
                  <DayName name="T" value="THURSDAY"></DayName>
                  <DayName name="F" value="FRIDAY"></DayName>
                  <DayName name="S" value="SATURDAY"></DayName>
                  <DayName name="S" value="SUNDAY"></DayName>
                </div>
                <div style={{ display: 'flex', marginTop: 30 }}>
                  <div>
                    <div>Start of day (time)</div>
                    <div>
                      <input
                        style={{
                          padding: '10px',
                          paddingLeft: '20px',
                          border: '1px solid #939598',
                          borderRadius: '4px',
                          width: '200px',
                        }}
                        type="time"
                        value={this.state.shiftStartTime}
                        onChange={(e) => {
                          this.setState({ shiftStartTime: e.target.value });
                          logPostHogEvent(ADMIN_ORGANIZATIONAL_HOUR_BUTTON);
                        }}
                      />
                    </div>
                  </div>
                  <div style={{ padding: '30px' }}>to</div>
                  <div>
                    <div>End of day (time)</div>
                    <div>
                      <input
                        style={{
                          padding: '10px',
                          paddingLeft: '20px',
                          border: '1px solid #939598',
                          borderRadius: '4px',
                          width: '200px',
                        }}
                        type="time"
                        value={this.state.shiftEndTime}
                        onChange={(e) => {
                          this.setState({ shiftEndTime: e.target.value });
                          logPostHogEvent(ADMIN_ORGANIZATIONAL_HOUR_BUTTON);
                        }}
                      />
                    </div>
                  </div>
                  <div>
                    <button
                      className="AuthButton AuthButtonInvert"
                      style={{
                        marginLeft: '30px',
                        width: '83%',
                        padding: '10px',
                        marginTop: '25px',
                      }}
                      onClick={this.removeShiftTimings}
                    >
                      RESET
                    </button>
                  </div>
                </div>
              </>
              <>
                <div className="HeadText" style={{ marginTop: 30 }}>
                  Upload day off audio
                </div>
                <div className="CaptionText">
                  This audio fileis played when customers call you during your
                  weekly designated off days
                </div>
                <div className="PopupInputContainer">
                  <input
                    type="file"
                    accept="audio/wav"
                    onChange={(e) => shiftAudioChange(e)}
                  />
                  <div className="PopupInputLabel">
                    New audio file (Max. 1 MB, .WAV format)
                  </div>
                  {this.state.shiftAudio && (
                    <span>
                      <audio
                        style={{
                          height: '24px',
                          marginTop: '3px',
                          marginBottom: '-2px',
                        }}
                        controls
                        src={this.state.shiftAudio}
                      />
                      <img
                        alt=""
                        style={{
                          marginTop: '-10px',
                          marginLeft: '20px',
                          cursor: 'pointer',
                        }}
                        onClick={(e) => this.setState({ shiftAudio: '' })}
                        src={Delete}
                      />
                    </span>
                  )}
                </div>
              </>
              <div className="BottomBorder" />
              <>
                <div className="HeadText" style={{ marginTop: 30 }}>
                  Add your holidays
                </div>
                <div className="CaptionText">
                  update your company's holiday calender here, then upload
                  holiday audio which will play when customers call during
                  holidays
                </div>
                <div
                  style={{
                    display: 'flex',
                    marginTop: 30,
                    width: '100%',
                    justifyContent: 'space-between',
                  }}
                >
                  <div className="HeadText" style={{ width: '80%' }}>
                    Holiday calender
                  </div>

                  <div className="AddBtn" style={{ width: '20%' }}>
                    <a
                      onClick={() => {
                        this.setState({ holidayName: null });
                        this.setState({ holidayDate: null });
                        this.setState({ holidayId: uuidv4() });
                        this.setState({ popupOpen: true });
                      }}
                    >
                      {' '}
                      +Add holiday
                    </a>
                  </div>
                  {/* <button
                    className="AuthButton AuthButtonInvert"
                    style={{
                      width: "100px",
                      height: "50px",
                      margin: 0,
                      marginLeft: 30
                    }}
                    onClick={this.removeShiftTimings}>
                    RESET
                  </button> */}
                </div>
                <div style={{ display: 'flex' }}>
                  <DataTable.Table style={{ width: '100%' }}>
                    <DataTable.Head>
                      <DataTable.CellHead>Date</DataTable.CellHead>
                      <DataTable.CellHead>Holiday</DataTable.CellHead>
                      <DataTable.CellHead>Actions</DataTable.CellHead>
                    </DataTable.Head>
                    <DataTable.Body>{holidayData}</DataTable.Body>
                  </DataTable.Table>
                </div>
                <div className="HeadText" style={{ marginTop: 30 }}>
                  Upload holiday audio
                </div>
                <div className="CaptionText">
                  This audio fileis played when customers call you during
                  holidays.
                </div>
                <div className="PopupInputContainer">
                  <input
                    type="file"
                    accept="audio/wav"
                    onChange={(e) => holidayAudioChange(e)}
                  />
                  <div className="PopupInputLabel">
                    New audio file (Max. 1 MB, .WAV format)
                  </div>
                  <div>
                    {this.state.holidayAudio && (
                      <span>
                        <audio
                          style={{
                            height: '24px',
                            marginTop: '3px',
                            marginBottom: '-2px',
                          }}
                          controls
                          src={this.state.holidayAudio}
                        />
                        <img
                          alt=""
                          style={{
                            marginTop: '-10px',
                            marginLeft: '20px',
                            cursor: 'pointer',
                          }}
                          onClick={(e) => this.setState({ holidayAudio: '' })}
                          src={Delete}
                        />
                      </span>
                    )}
                  </div>
                </div>
              </>

              <div className="ErrorContainer ErrorContainerMargin">
                {this.state.error ? (
                  <React.Fragment>
                    <img
                      alt=""
                      src={InfoRed}
                      style={{
                        paddingRight: '5px',
                        position: 'relative',
                        top: '-2px',
                      }}
                    />
                    {this.state.error}
                  </React.Fragment>
                ) : null}
              </div>
              <div style={{ display: 'flex', marginTop: 30 }}>
                <button
                  className="AuthButton AuthButtonSave"
                  onClick={this.saveData}
                >
                  SAVE
                </button>
                <button
                  className="AuthButton AuthButtonInvert"
                  onClick={this.fetchHolidayData}
                >
                  CANCEL
                </button>
              </div>
            </div>
          </div>
        </div>
        {holidayPopup}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  sidebarClosed: state.sidebar.sidebarClosed,
  isHideCustomerEnabled: state.topbar.isHideCustomerEnabled,
  isCampaignEnabled: state.topbar.isCampaignEnabled,
});

export default styled(connect(mapStateToProps, {})(OffHoursPage))`
  .ContentContainer {
    /* width: 98%; */
    /* margin: 1%; */
    background: ${(props) => props.theme.colors.acccountsettingBgColor};
    height: calc(100vh - 60px);
    display: flex;
    flex-direction: column;
    /* justify-content: space-between; */
    overflow: auto;
  }

  .Content {
    margin-left: 230px;
    height: 100vh;
    transition: margin 0.3s;
  }

  .ContentFull {
    margin-left: 72px;
    height: 100vh;
    transition: margin 0.3s;
  }

  @media only screen and (max-width: 900px) {
    .ContentContainer {
      width: 98%;
      margin: 1%;
      height: calc(100vh - 100px);
      border-radius: 4px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      overflow: auto;
    }
  }

  .column {
    float: left;
    width: 25%;
    padding: 0 0px;
  }

  @media screen and (max-width: 600px) {
    .column {
      width: 100%;
      display: block;
      margin-bottom: 0px;
    }
  }

  .AuthSubHeader {
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    margin: 0;
    letter-spacing: normal;
    color: ${(props) => props.theme.colors.topBarAccTextColor};
  }

  .HeadText {
    font-size: 19px;
    font-weight: 700;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: ${(props) => props.theme.colors.topBarAccTextColor};
  }
  .CaptionText {
    color: ${(props) => props.theme.colors.tableContentColor};
  }
  .outer-circle {
    width: 45px;
    height: 45px;
    border: 1px solid var(--secondary-color2);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 25px;
    cursor: pointer;
    color: ${(props) => props.theme.colors.tableContentColor};
  }
  //   .inner-circle {
  //     width: 32px;
  //     height: 32px;
  //     background: ${(props) => props.theme.colors.tableBgColor};
  //     border-radius: 50%;
  //     display: flex;
  //     align-items: center;
  //     justify-content: center;
  //     font-family: Montserrat;
  //     line-height: 1.19;
  //     text-align: center;
  //     color: ${(props) => props.theme.colors.tableContentColor};
  //   }

  .PopupInputContainer {
    margin-top: 15px;
  }

  .InputBox {
    padding-left: 5%;
    width: 100%;
    height: 44px;
    font-size: 16px;
    border-radius: 4px;
    border: solid 1px #939598;
    margin-top: 7px;
    margin-bottom: 7px;
    color: ${(props) => props.theme.colors.topBarAccTextColor};
    background-color: ${(props) => props.theme.colors.topBarBg};
  }

  .AuthButton {
    width: 30%;
    padding: 15px 50px 14px;
    margin-top: 8px;
    margin-bottom: 8px;
    margin-right: 20px;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: 0.75px;
    text-align: center;
    color: var(--white-color);
    border-radius: 4px;
    border-width: 0;
  }
  .AuthButtonInvert {
    border: solid 1px #ed3833;
    color: #ed3833;
    background-color: ${(props) => props.theme.colors.topBarBg};
  }
  .AuthButtonSave {
    // border: solid 1px #ed3833;
    color: #fff;
    background-color: ${(props) => props.theme.colors.tabSelectedColor};
  }
  .AddBtn {
    cursor: pointer;
    color: #419bf9;
    width: 100%;
    text-align: left;
    padding-left: 5px;
    text-decoration: none;
    font-size: 15px;
    font-weight: 600;
  }
  .DeleteBtn {
    cursor: pointer;
    right: 8px;
    top: 9px;
    width: 18px;
    height: 18px;
  }
  .ErrorContainer {
    width: 50%;
    min-height: 19px;
    font-size: 17px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    /* line-height: 3.92; */
    letter-spacing: normal;
    text-align: left;
    color: var(--red-color);
  }

  .ErrorContainerMargin {
    margin-top: 16px;
    margin-bottom: 18px;
  }
`;
