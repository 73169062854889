import React, { Component } from 'react';
import { toast } from 'react-toastify';
import Popup from '../../../common/component/popups/popups';
import '../../../assets/css/Popups.css';
import InfoRed from '../../../assets/images/infoRed.png';
import Close from '../../../assets/images/popupClose.png';
import { POST } from '../../../services/HttpRequests';
import ExportUsersPopup from './ExportUsersPopup';
import PreLoader from '../../../common/component/preLoader/PreLoader';
import { isSuperAdminWithPermission } from '../../../utility/utility';
import {
  ADMIN_ADD_AGENT,
  ADMIN_ADD_AGENT_MANUALLY,
  ADMIN_ADD_AGENT_UPLOAD_CSV,
} from '../../../common/posthog/events';
import { logPostHogEvent } from '../../../common/posthog/posthog';
const URL = process.env.REACT_APP_SAMPLE_FILE_URL;
const userUrl = process.env.REACT_APP_USER_API;
class PopTwo extends Component {
  constructor() {
    super();
    this.state = {
      popupOpen: false,
      name: '',
      number: '',
      email: '',
      step: 1,
      csvFile: null,
      error: '',
      emp_id: '',
      is_webrtc_enabled: false,
      processing: false,
      manager: '',
    };
    this.initialState = this.state;
  }

  openPopup = () => {
    logPostHogEvent(ADMIN_ADD_AGENT);
    this.setState({ popupOpen: true });
  };
  closePopup = () => {
    this.setState(this.initialState);
  };
  inputHandler = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };

  checkboxToggleHandler = () => {
    this.setState({ is_webrtc_enabled: !this.state.is_webrtc_enabled });
  };

  fileUploadHandler = (e) => {
    this.setState({ csvFile: e.target.files[0] });
  };

  switchStepHandler = (step) => {
    this.setState({ step: step });
  };

  manualDataHandler = () => {
    if (!this.state.name || !this.state.email || !this.state.number) {
      this.setState({
        error: 'Name, Phone Number and Email are required fields.',
      });
      return;
    }
    if (this.state.processing) return;
    this.setState({ processing: true });

    let data = {
      emp_id: this.state.emp_id,
      name: this.state.name,
      phone_number: this.state.number,
      email: this.state.email,
      organisationId: localStorage.getItem('doosra-biz-organisation-id'),
      is_webrtc_enabled: this.props.isWebrtcEnabled || false,
    };
    if (this.state.manager) {
      data.managerId = this.state.manager;
    }
    POST(`${userUrl}v1/user`, data)
      .then((res) => {
        return res.data.response;
      })
      .then((res) => {
        this.closePopup();
        // window.location.reload()
        this.props.refreshList();
        toast.success('Agent added Successfully');
        this.setState({
          popupOpen: false,
          name: '',
          number: '',
          email: '',
          step: 1,
          csvFile: null,
          error: '',
          emp_id: '',
          processing: false,
          manager: ''
        });
      })
      .catch((err) => {
        if (
          err &&
          err.response &&
          err.response.data &&
          err.response.data.error
        ) {
          this.setState({
            error: err.response.data.error.reason,
            processing: false,
          });
          toast.error(err.response.data.error.reason);
        }
      });
  };

  csvUploadHandler = () => {
    if (this.state.processing) return;
    if (!this.state.csvFile) return;
    this.setState({ processing: true });
    let formData = new FormData();
    formData.append('users', this.state.csvFile, this.state.csvFile.name);
    formData.append(
      'organisationId',
      localStorage.getItem('doosra-biz-organisation-id')
    );

    POST(`${userUrl}v1/user/bulk`, formData)
      .then((res) => {
        return res.data.response;
      })
      .then((res) => {
        // window.location.reload()

        this.closePopup();
        this.props.refreshList();
        toast.success(res.status);
        if (res.existing_users.length > 0)
          toast.warning(`${res.existing_users.length} duplicate(s) found`);
        if (res.un_mapped_users.length > 0)
          toast.warning(
            `${res.un_mapped_users.length} Primary number(s) cannot be mapped as Virtual number(s).`
          );
        if (res.existing_users.length > 0) {
          // eslint-disable-next-line array-callback-return
          res.existing_users.map((item) => {
            toast.error(`${item.phone_number} already exists`);
          });
        }
        if (res.un_mapped_users.length > 0) {
          // eslint-disable-next-line array-callback-return
          res.existing_users.map((item) => {
            toast.error(`${item.phone_number} not mapped`);
          });
        }
        this.setState({ processing: false });
      })
      .catch((err) => {
        if (
          err &&
          err.response &&
          err.response.data &&
          err.response.data.error
        ) {
          this.setState({ error: err.response.data.error.reason });
          toast.error(err.response.data.error.reason);
          this.setState({ processing: false });
        }
      });
  };

  render() {
    let org_name = localStorage.getItem('doosra-biz-organisation-name') || '';
    let currentView;
    const basicUserDetailDownloadCSV = this.props.basicUserDownloadDetail;
    let columns = [
      { id: 'emp_id', displayName: 'EMPLOYEE ID' },
      { id: 'zvr_name', displayName: 'AGENT NAME' },
      { id: 'v_mobile_no', displayName: 'VIRTUAL NO' },
      { id: 'zvr_mobile_no', displayName: 'PERSONAL PHONE NO' },
      { id: 'created_at', displayName: 'ADDED ON' },
    ];
    let isFreeTrail = localStorage.getItem('is_free_trial_enabled');
    isFreeTrail = isFreeTrail ? (isFreeTrail === 'true' ? true : false) : false;
    switch (this.state.step) {
      case 3:
        currentView = (
          <div className="PopupContainer" style={{ overflow: 'auto' }}>
            <div className="PopupHeaderContainer">
              <p>Add a Agent</p>
              <img
                alt=""
                className="PopupClose"
                src={Close}
                onClick={this.closePopup}
              />
            </div>
            <div className="PopupBody">
              {/* {(this.props.isWebrtcEnabled || this.props.isCCWebrtcEnabled) && (
                <div className="PopupInputContainer">
                  <label className="PopupInputLabel">WEBRTC</label>
                  <input
                    type="checkbox"
                    id="is_webrtc_enabled"
                    value={this.state.is_webrtc_enabled}
                    onChange={this.checkboxToggleHandler}
                    style={{ marginLeft: '10px' }}
                  />
                </div>
              )} */}
              <div className="PopupInputContainer">
                <label className="PopupInputLabel">Employee Id</label>
                <input
                  className="PopupInput"
                  id="emp_id"
                  value={this.state.emp_id}
                  onChange={this.inputHandler}
                />
              </div>
              <div className="PopupInputContainer">
                <label className="PopupInputLabel">Name</label>
                <input
                  className="PopupInput"
                  id="name"
                  value={this.state.name}
                  onChange={this.inputHandler}
                />
              </div>
              <div className="PopupInputContainer">
                <label className="PopupInputLabel">Phone Number</label>
                <input
                  className="PopupInput"
                  id="number"
                  value={this.state.number}
                  onChange={this.inputHandler}
                />
              </div>
              <div className="PopupInputContainer">
                <label className="PopupInputLabel">Email</label>
                <input
                  className="PopupInput"
                  id="email"
                  type="email"
                  value={this.state.email}
                  onChange={this.inputHandler}
                />
              </div>
              <div className="PopupInputContainer">
                <label className="PopupInputLabel">Manager Id</label>
                <select
                  className="PopupInput"
                  id="manager"
                  type="manager"
                  value={this.state.manager}
                  onChange={
                    (e) => this.setState({ manager: e.target.value })
                  }
                >
                  <option value='' disabled>select</option>
                  {this.props.managersData.length &&
                    this.props.managersData.map(
                      i => <option key={i._id} value={i._id}>{i.name}</option>)
                  }
                </select>
              </div>
              {this.state.error ? (
                <div className="ErrorContainer ErrorContainerMargin">
                  <img
                    alt=""
                    src={InfoRed}
                    style={{
                      paddingRight: '5px',
                      position: 'relative',
                      top: '-2px',
                    }}
                  />
                  {this.state.error}
                </div>
              ) : null}

              <p className="PopupNote">
                Note: All incoming calls will be forwarded to this agent through
                the phone number you add here, verify whether their number is
                active and in-use before adding a number.
              </p>
              <button
                className="ButtonFullWidth BgGreen"
                onClick={this.manualDataHandler}
                style={{ marginTop: '20px' }}
              >
                SAVE
              </button>
            </div>
          </div>
        );
        break;
      case 2:
        currentView = (
          <div className="PopupContainer">
            <div className="PopupHeaderContainer">
              <p>Upload CSV</p>
              <img
                alt=""
                className="PopupClose"
                src={Close}
                onClick={this.closePopup}
              />
            </div>
            <div
              className="PopupBody"
              style={{ textAlign: 'center', marginTop: '20px' }}
            >
              <p className="SmallTextSize ColorRed">
                The CSV file you upload needs to be in the following format
              </p>
              <p>
                <a href={URL} download>
                  Download Sample File
                </a>
              </p>
              {/*
              <p className="SmallTextSize ">Agent Name, Agent Personal Number</p>
              <CSVReader onFileLoaded={(data) => this.csvUploadHandler(data)} />
            */}

              <input
                id="csvFile"
                type="file"
                onChange={this.fileUploadHandler}
              />
              <button
                className="ButtonFullWidth"
                style={{ marginTop: '10px' }}
                onClick={this.csvUploadHandler}
              >
                UPLOAD FILE
              </button>
            </div>
          </div>
        );
        break;
      default:
      case 1:
        currentView = (
          <div className="PopupContainer">
            <div className="PopupHeaderContainer">
              <p>Add Agent</p>
              <img
                alt=""
                className="PopupClose"
                src={Close}
                onClick={this.closePopup}
              />
            </div>
            <div className="PopupBody">
              <button
                className="AddUserFullWidth"
                style={{ marginTop: '15px' }}
                onClick={() => {
                  logPostHogEvent(ADMIN_ADD_AGENT_MANUALLY);
                  this.switchStepHandler(3);
                }}
              >
                ADD AN AGENT MANUALLY
              </button>
              {!isFreeTrail && (
                <button
                  className="AddUserFullWidth"
                  style={{ marginTop: '15px' }}
                  onClick={() => {
                    logPostHogEvent(ADMIN_ADD_AGENT_UPLOAD_CSV);
                    this.switchStepHandler(2);
                  }}
                >
                  UPLOAD CSV
                </button>
              )}
            </div>
          </div>
        );
        break;
    }

    let popper = (
      <Popup
        open={this.state.popupOpen}
        closeOnDocumentClick={false}
        closeOnEscape={false}
      >
        {currentView}
      </Popup>
    );
    return (
      <div style={{ display: 'flex' }}>
        {this.state.processing && <PreLoader />}
        <span>
          <button
            className="Otherbutton"
            style={{ marginRight: 0 }}
            onClick={this.openPopup}
            disabled={!isSuperAdminWithPermission('ADD')}
          >
            ADD AGENT
          </button>
          {popper}
        </span>
        <span>
          <ExportUsersPopup />
        </span>
      </div>
    );
  }
}

export default PopTwo;
