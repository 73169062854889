import React, {useCallback, useEffect, useState} from 'react';
import BasicFlow from "../setting/container/BasicFlow";
import BottomTabs from "./BottomTab";
import CallFlowStyle from "../callFlow/CallFlow.css";
import {
  ReactFlow,
  useNodesState,
  useEdgesState,
  addEdge, 
  Background,
  BackgroundVariant,
  MiniMap,
  Controls,
  useReactFlow
} from "reactflow";
import StartIvr from './StartIvr';
import PlayAudio from './PlayAudio';
import Menu from "./Menu";
import Agent from "./Agent";
import GroupCall from "./GroupCall";
import EndCall from "./EndCall";
import CustomEdge from "./CustomEdge";
import Menubar from "./Menubar";
import { useSelector } from 'react-redux';
import { POST, GET, PUT } from '../../services/HttpRequests';
import CallFlowHeader from './CallFlowHeader';
import Sidebar from './Sidebar';
import CallFlowSubHeader from './CallFlowSubHeader';
import SidebarAlohaa from '../../common/component/sidebar/Sidebar';
import { toast } from 'react-toastify';

const ivrUrl = process.env.REACT_APP_IVR_API;


const initialNodes = [];
const initialEdges = [];

// startIvr: (nodeProps) => ( <StartIvr {...nodeProps} onDelete={nodeProps.data.onDelete} /> ),
 

const nodeTypes = {
  startIvr: (nodeProps) => ( <StartIvr {...nodeProps} onDelete={nodeProps.data.onDelete} /> ),
  playAudio: (nodeProps) => ( <PlayAudio {...nodeProps} onDelete={nodeProps.data.onDelete} /> ),
  menu: (nodeProps) => ( <Menu {...nodeProps} onDelete={nodeProps.data.onDelete} updateNodeData={nodeProps.data.updateNodeData} /> ),
  agent: (nodeProps) => ( <Agent {...nodeProps} onDelete={nodeProps.data.onDelete} /> ),
  groupCall: (nodeProps) => ( <GroupCall {...nodeProps} onDelete={nodeProps.data.onDelete} /> ),
  endCall: (nodeProps) => ( <EndCall {...nodeProps} onDelete={nodeProps.data.onDelete} /> ),
};
const edgeTypes = {
  'custom-edge': CustomEdge
}

const CallFlow = (props) => {
  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);
  const [rfInstance, setRfInstance] = useState(null);
  const [isUpdateCallFlow, setIsUpdateCallFlow] = useState(false);
  const [dids, setDids] = useState([]);
  const [agents, setAgents] = useState([]);
  const [groups, setGroups] = useState([]);
  const [audioFiles, setAudioFiles] = useState([]);
  const flowkey = 'flow-key';

  const { callFlowName, documentId } = props.location.state || "";

  const defaultViewport = { x: 0, y: 0, zoom: 1 };

  useEffect(() => {
    if(documentId) {
      setIsUpdateCallFlow(true)
      const params = {
        organisationId: localStorage.getItem('doosra-biz-organisation-id'),
        documentId
      }
      GET(`${ivrUrl}v1/ivr/organisation/get-one-call-flow`, params).then((res) => {
        if (res?.data?.success) {
          setNodes(res?.data?.response?.draft?.[0]?.nodes);
          setEdges(res?.data?.response?.draft?.[1]?.edges);
        }
      }).catch((err) => {
        toast.error(err?.response?.data?.error?.reason || 'An error occured');
      })
      // GET(`http://localhost:14018/v1/ivr/organisation/get-one-call-flow`, params).then((res) => {
      //   if (res?.data?.success) {
      //     setNodes(res?.data?.response?.draft?.[0]?.nodes);
      //     setEdges(res?.data?.response?.draft?.[1]?.edges);
      //   }
      // }).catch((err) => {
      //   console.log("error====", err)
      // })
    }
  }, [documentId]);

  useEffect(() => {
    fetchAllData();
  }, []);

  const fetchDids = async () => {
    const params = {
      organisationId: localStorage.getItem('doosra-biz-organisation-id'),
    }
    const res = await GET(`${ivrUrl}v1/ivr/organisation/vmn-list`, params);
    let Dids = [];

    if (res?.data?.success) {
      Dids = res?.data?.response
    }
    return Dids;
  }

  const fetchAgents = async () => {
    const params = {
      organisationId: localStorage.getItem('doosra-biz-organisation-id'),
    }
    const res = await GET(`${ivrUrl}v1/ivr/organisation/user-list`, params);
    let agents = [];

    if (res?.data?.success) {
      agents = res?.data?.response
    }
    return agents;
  }

  const fetchGroups = async () => {
    const params = {
      organisationId: localStorage.getItem('doosra-biz-organisation-id'),
    }
    const res = await GET(`${ivrUrl}v1/ivr/organisation/group-list`, params);
    let Groups = [];

    if (res?.data?.success) {
      Groups = res?.data?.response
    }
    return Groups;
  }

  const fetchAllData = async () => {
     const response = await fetchDids();
     const Dids = response?.map((did) => did?.v_mobile_no)
     setDids(Dids);

     const agentsResponse = await fetchAgents();
     setAgents(agentsResponse);

     const groupResponse = await fetchGroups();
     setGroups(groupResponse);
  }

  const isSideBarOpen = useSelector(state => state?.callFlow?.isSideBarOpen);
  const isAlohaaSideBarOpen = useSelector(state => state?.sidebar?.sidebarClosed);

  const onDragOver = useCallback((event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = 'move';
  }, []);

  const onDrop = (event) => {
    event.preventDefault();
    const type = event.dataTransfer.getData('application/reactflow/type');
    const label = event.dataTransfer.getData('application/reactflow/label');
    
    if (typeof type === 'undefined') {
      return;
    }

    const position = rfInstance.screenToFlowPosition({
      x: event.clientX,
      y: event.clientY,
    });
    
    
    const newNode = {
      id: Math.floor(Math.random() * 1000).toString(), // or a more unique ID generation
      type,
      position,
      data: { label },
    };

    rfInstance.setViewport(defaultViewport.x, defaultViewport.y, defaultViewport.zoom);

    setNodes((es) => es.concat(newNode));
  };

  const updateNodeData = useCallback(
    (props) => {
      let data = props?.data;
      if (data?.additionalInfo) {
        delete data.additionalInfo;
      }
      const updatednode  = {
        id: props?.id,
        data: data,
        type: props?.type,
        position: {
          x: props?.xPos, y: props?.yPos
        },
        ...props
      }
      setNodes((nds) => nds.map((node) => (node.id === props.id ? updatednode : node)));
    },
    [setNodes]
  );

  const handleDeleteNode = useCallback(
    (id) => {
      setNodes((nds) => nds.filter((node) => node.id !== id));
      setEdges((eds) => eds.filter((edge) => edge.source !== id && edge.target !== id));
      // setEdges((eds) => eds.filter((edge) => edge.source !== id && edge.target !== id));
    },
    [setNodes, setEdges]
  );

  const updatedNodes = nodes.map((node) => ({
    ...node,
    data: { ...node.data, onDelete: handleDeleteNode, updateNodeData: updateNodeData, }, // Add the onDelete function to the node's data
  }));

  const isValidConnection = useCallback(
    async (connection) => {
      const sourceNode = nodes.find((node) => node.id === connection.source);
    const targetNode = nodes.find((node) => node.id === connection.target);
    const isValidConnectionBetweenEdges = await validateConnection(sourceNode, targetNode);
    return isValidConnectionBetweenEdges;
    },
    [nodes],
  );

  const onConnect = useCallback(
    async (connection) => {
      const edge = { ...connection, type: 'custom-edge' };
      const isValid = await isValidConnection(connection);
      if (isValid) {
        setEdges((eds) => addEdge(edge, eds));
      } else {
        toast.error("Not a valid connection");
      }
    },
    [setEdges, isValidConnection],
  );

  const handleSaveData = useCallback((nodeId, data) => {
    setNodes((prevNodes) =>
      prevNodes.map((node) =>
        node.id === nodeId
          ? { ...node, data: { ...node.data, label: data?.label, additionalInfo: data?.additionalInfo }}
          : node
      )
    );
    if (data?.additionalInfo === "MENU" || data?.additionalInfo === "AUTOATTENDANT") {
      let currentAudioFiles = {
        audioFile: data?.audioFile,
        nodeId
      }
      setAudioFiles((prevDataArray) => {
        const index = prevDataArray.findIndex((item) => item.nodeId === nodeId);
        if (index !== -1) {
          const updatedArray = [...prevDataArray];
          updatedArray[index] = currentAudioFiles; 
          return updatedArray;
        } else {
          return [...prevDataArray, currentAudioFiles];
        }
      });
    }
  }, [])

  const checkAllNodesConnected = () => {
    const nodeIds = nodes.map((node) => node.id); // Get all node IDs

    const menuNodes = nodes.filter((node) => node.type === "menu");

    let isMenuNodesConnected = true;

    for (let i = 0; i < menuNodes?.length; i++) {
      const node = menuNodes[i];
      const connectedEdges = edges.filter((edge) => edge?.source === node?.id);
      const isMenuSubNodesConected = node?.data?.label?.length === connectedEdges?.length;
      if (isMenuSubNodesConected) {
        isMenuNodesConnected = isMenuSubNodesConected;
      } else {
        isMenuNodesConnected = false;
        break;
      }
    }

    // Check if each node is either a source or a target in any edge
    const allConnected = nodeIds.every((nodeId) =>
      edges.some((edge) => edge.source === nodeId || edge.target === nodeId)
    );

    if (isMenuNodesConnected && allConnected) {
      return true;
    } return false;
  };
  
  const validateNodes = (nodes) => {
    let isValidNode = true;
    for (let i = 0; i < nodes?.length; i++) {
      const node = nodes[i];
      if (node?.data?.label !== "" || node?.type === "endCall") {
        isValidNode = true
      } else {
        isValidNode = false;
        break;
      }
    }
    return isValidNode;
  }
  const handleGoLive = () => {
    const isValidCallFlow = validateNodes(nodes);
    const  isAllNodesConnected = checkAllNodesConnected();
    if (isValidCallFlow && isAllNodesConnected) {
      const formData = new FormData();
      formData.append("nodes", JSON.stringify(nodes));
      formData.append("edges", JSON.stringify(edges));
      formData.append("isGolive", true);
      formData.append("ivrName", callFlowName);
      formData.append("organisationId", localStorage.getItem('doosra-biz-organisation-id'));
      audioFiles.forEach((file, index) => {
        formData.append('audios', file?.audioFile); // 'audios' should match your Multer config field name
      });
      const nodeIds = audioFiles.map((x) => ( x.nodeId ));
      formData.append("audioNodeIds", JSON.stringify(nodeIds));
      if (isUpdateCallFlow) {
        formData.append("documentId", documentId);
      }
      if (isUpdateCallFlow) {
        // PUT(`http://localhost:14018/v1/ivr/organisation/call-flow-update`, {}, formData).then((res) => {
        //   if (res?.data?.success) {
        //     // props.history.push('/call-flows')
        //   }
        // }).catch((err) => {
        //   console.log("err at callflow creation", err)
        // })

        PUT(`${ivrUrl}v1/ivr/organisation/call-flow-update`, {}, formData).then((res) => {
          if (res?.data?.success) {
            props.history.push('/call-flows')
          }
        }).catch((err) => {
          toast.error(err?.response?.data?.error?.reason || 'An error occured');
        })

      } else {
        // POST(`http://localhost:14018/v1/ivr/organisation/call-flow-create`, formData).then((res) => {
        //   if (res?.data?.success) {
        //     // props.history.push('/call-flows')
        //   }
        // }).catch((err) => {
        //   console.log("err at callflow creation", err)
        // })

        POST(`${ivrUrl}v1/ivr/organisation/call-flow-create`, formData).then((res) => {
          if (res?.data?.success) {
            props.history.push('/call-flows')
          }
        }).catch((err) => {
          toast.error(err?.response?.data?.error?.reason || 'An error occured');
        })
      }
    } else {
      toast.error("Not a valid callflow");
    }
  }
  const handleSaveAsDraft = async() => {
    if (rfInstance) {
      // need to remove if else and make one
      const flow = rfInstance.toObject();
      const flowObject = JSON.stringify(flow)
      localStorage.setItem(flowkey, flowObject);
      const formData = new FormData();
      if (!isUpdateCallFlow) {
        formData.append("nodes", JSON.stringify(nodes));
        formData.append("edges", JSON.stringify(edges));
        formData.append("isGolive", false);
        formData.append("ivrName", callFlowName);
        formData.append("organisationId", localStorage.getItem('doosra-biz-organisation-id'));
        audioFiles.forEach((file, index) => {
          formData.append('audios', file?.audioFile); // 'audios' should match your Multer config field name
        });
        const nodeIds = audioFiles.map((x) => ( x.nodeId ));
        formData.append("audioNodeIds", JSON.stringify(nodeIds));
        // formData.append("isUpdateCallFlow", isUpdateCallFlow);
        POST(`${ivrUrl}v1/ivr/organisation/call-flow-create`, formData).then((res) => {
          if (res?.data?.success) {
            props.history.push('/call-flows')
          }
        }).catch((err) => {
          toast.error(err?.response?.data?.error?.reason || 'An error occured');
        })

        // POST('http://localhost:14018/v1/ivr/organisation/call-flow-create', formData).then((res) => {
        //   if (res?.data?.success) {
        //     // props.history.push('/call-flows')
        //   }
        // }).catch((err) => {
        //   console.log("err at callflow creation", err)
        // })
      } else {
        formData.append("nodes", JSON.stringify(nodes));
        formData.append("edges", JSON.stringify(edges));
        formData.append("isGolive", false);
        formData.append("ivrName", callFlowName);
        formData.append("organisationId", localStorage.getItem('doosra-biz-organisation-id'));
        audioFiles.forEach((file, index) => {
          formData.append('audios', file?.audioFile); // 'audios' should match your Multer config field name
        });
        const nodeIds = audioFiles.map((x) => ( x.nodeId ));
        formData.append("audioNodeIds", JSON.stringify(nodeIds));
        formData.append("documentId", documentId);

        PUT(`${ivrUrl}v1/ivr/organisation/call-flow-update`, {}, formData).then((res) => {
          if (res?.data?.success) {
            props.history.push('/call-flows')
          }
        }).catch((err) => {
          toast.error(err?.response?.data?.error?.reason || 'An error occured');
        })

        // PUT('http://localhost:14018/v1/ivr/organisation/call-flow-update', {}, formData).then((res) => {
        //   if (res?.data?.success) {
        //     // props.history.push('/call-flows')
        //   }
        // }).catch((err) => {
        //   console.log("err at callflow creation", err)
        // })
        
      }
    }
  }
  const isConnectedToMultipleNodes = (sourceNode) => {
    const sourceConnections = edges.filter((edge) => edge.source === sourceNode?.id);
    const maxConnections = 1;
    if (sourceConnections.length >= maxConnections) {
      return true;
    } return false;
    
  }
  const validateConnection = async (sourceNode, targetNode) => {
    // rule 1
    if (!sourceNode || !targetNode)return false;

    // rule 2
    if(sourceNode?.type === "startIvr" && (targetNode?.type === "startIvr" || targetNode?.type === "groupCall" || targetNode?.type === "agent" || targetNode?.type === "endCall")) return false;

    // rule 3
    if(sourceNode?.type === "playAudio" && (targetNode?.type == "playAudio" || targetNode?.type == "startIvr" || targetNode?.type == "agent" || targetNode?.type == "groupCall" || targetNode?.type == "menu")) return false;

    // rule 4
    if(sourceNode?.type === "menu" && (targetNode?.type === "startIvr" || targetNode?.type === "endCall")) return false;

    // rule 5
    if(sourceNode?.type === "agent" && (targetNode?.type === "agent" || targetNode?.type === "groupCall" || targetNode?.type === "menu" || targetNode?.type === "playAudio" || targetNode?.type === "startIvr")) return false;

    // rule 6
    if(sourceNode?.type === "groupCall" && (targetNode?.type === "agent" || targetNode?.type === "groupCall" || targetNode?.type === "menu" || targetNode?.type === "playAudio" || targetNode?.type === "startIvr")) return false;

    if (sourceNode?.type !== "menu") {
      if (isConnectedToMultipleNodes(sourceNode)) {
        return false;
      };
    };
    return true;
  }
  
  return (
    <div 
      style={{ position: 'relative'}}
    >
      <div>
        <CallFlowHeader />
      </div>
      <div>
        <CallFlowSubHeader props={props} callFlowName={callFlowName} handleGoLive={handleGoLive} handleSaveAsDraft={handleSaveAsDraft} isAlohaaSideBarOpen={isAlohaaSideBarOpen} />
      </div>
      <div style={{ position: "absolute", zIndex: 2 }}>
        <SidebarAlohaa />
      </div>
      {isSideBarOpen && <div className="SidebarContainer">
        <Menubar  handleSaveData={handleSaveData} dids={dids} agents={agents} groups={groups} />
      </div>}
      <div style={{  height: '100vh', }}>
      <ReactFlow
        nodes={updatedNodes}
        edges={edges}
        nodeTypes={nodeTypes}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        onDragOver={onDragOver}
        onDrop={onDrop}
        onInit={setRfInstance}
        edgeTypes={edgeTypes}
        onConnect={onConnect}
        minZoom={0.50}
        maxZoom={2}
        defaultViewport={defaultViewport}
        isValidConnection={isValidConnection}
      >
        <Background  variant="dots" gap={15} size={1}  />
        <MiniMap nodeStrokeWidth={3} />
        <Controls />
        </ReactFlow>
      </div>
      <div className="BottomTabs">
        <BottomTabs />
      </div>
    </div>
  )
};

export default CallFlow;