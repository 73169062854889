/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { withRouter, useLocation, useHistory } from 'react-router-dom';
import * as onbaordingTourConstants from '../../../assets/onbaordingTourConstants';
import {
  toggleSidebar,
  setSMSAccessKey,
  setCallAccessKey,
} from '../../../actions/sidebarActions';
import styled from '@emotion/styled';
import '../../../assets/css/Sidebar.css';
import { DELETE } from '../../../services/HttpRequests';
import { SIDEBAR_BUTTON, AVAILABILITY_TOGGLE } from '../../posthog/events';
import { logPostHogEvent } from '../../posthog/posthog';
import phone from '../../../assets/IconsV2/call.png';
import schema from '../../../assets/IconsV2/schema.png';
import campaign from '../../../assets/IconsV2/campaign.png';
import recordVoiceOver from '../../../assets/IconsV2/record_voice_over.png';
import widgets from '../../../assets/IconsV2/widgets.png';
import manageAccounts from '../../../assets/IconsV2/manage_accounts.png';
import rupee from '../../../assets/IconsV2/currency_rupee.png';
import avgPace from '../../../assets/IconsV2/avg_pace.png';
import phoneSetting from '../../../assets/IconsV2/phonelink_setup.png';
import article from '../../../assets/IconsV2/article.png';
import terminal from '../../../assets/IconsV2/terminal.png';
import webhook from '../../../assets/IconsV2/webhook.png';

const userUrl = process.env.REACT_APP_USER_API;
const incomingCallsUrl = process.env.REACT_APP_INCOMING_CALLS_API;
const organizationUrl = process.env.REACT_APP_ORGANIZATION_API;
const webRTCOutGoingUrl = process.env.REACT_APP_WEBRTC_RECOVERY_API_URL;
const cloudCallUrl = process.env.REACT_APP_CLOUD_CALL_CENTER;

const isSuperAdmin =
  localStorage.getItem('doosra-biz-user-role-is-super-admin') === 'true';

const SidebarMainButton = ({
  name,
  isVisible,
  isActive,
  icon,
  linkedUrl,

  callback,
}) => {
  // const history = useHistory();

  const handleClick = () => {
    // console.log('test', !!callback, !!linkedUrl);
    // if (callback) {
    callback();
    // } else if (linkedUrl) {
    //   history.push(linkedUrl); // Navigate to the provided URL
    // }
  };

  if (isVisible === false) return null;

  return (
    <div className="SidebarMainButtonHolder" onClick={handleClick}>
      <div
        className={isActive ? 'SidebarMainButtonActive' : 'SidebarMainButton'}
      >
        <img
          alt=""
          src={icon}
          style={isActive ? { filter: 'brightness(0) invert(1)' } : null}
          className="SidebarItemIcon"
        />
      </div>
      <p
        className={
          isActive ? 'SidebarMainButtonTextActive' : 'SidebarMainButtonText'
        }
      >
        {name}
      </p>
    </div>
  );
};

const SubMenuSideBarButton = ({ name, icon, url, action }) => {
  const history = useHistory();
  return (
    <div
      className="SubMenuSidebarButton"
      onClick={() => {
        if (action) {
          action();
        } else {
          history.push(url);
        }
      }}
    >
      <img alt="" src={icon} className="SidebarItemIcon" />
      {name}
    </div>
  );
};

const SettingsMenu = ({}) => {
  return (
    <div className="SubMenuSideBar">
      <h3 className="SubMenuSidebarTitle">Settings</h3>
      <p className="SubMenuSidebarSubTitle">Account</p>
      <div className="SubMenuSidebarButtonGroup">
        <SubMenuSideBarButton
          name="Profile Settings"
          icon={manageAccounts}
          url="account-settings"
        />
        <SubMenuSideBarButton
          name="Plan Settings"
          icon={rupee}
          url="payment-history"
        />
        <SubMenuSideBarButton
          name="Operating Schedule"
          icon={avgPace}
          url="off-hours"
        />
        <SubMenuSideBarButton
          name="Call Settings"
          icon={phoneSetting}
          url="call-settings"
        />

        <SubMenuSideBarButton name="Kyc" icon={phoneSetting} url="kyc" />
      </div>
      <p className="SubMenuSidebarSubTitle">Developer Resources</p>
      <div className="SubMenuSidebarButtonGroup">
        <SubMenuSideBarButton name="Documentation" icon={article} />
        <SubMenuSideBarButton
          name="API Key"
          icon={terminal}
          action={() => window.open('https://docs.alohaa.ai/', '_blank')}
        />
        <SubMenuSideBarButton name="Webhooks" icon={webhook} />
      </div>
    </div>
  );
};

const Sidebar = ({ className }) => {
  const location = useLocation();
  const history = useHistory();
  const currentPath = location.pathname;
  const [isLoading, setIsLoading] = useState(false);
  const [isFreeTrail, setIsFreeTrail] = useState(true);
  console.log(
    'Current Path:',
    currentPath,
    currentPath === '/account-settings'
  );
  const [settingsMenuOpen, setSettingsMenuOpen] = useState(
    [
      '/account-settings',
      '/off-hours',
      '/call-settings',
      '/payment-history',
      '/kyc',
    ].includes(currentPath)
      ? true
      : false
  );

  useEffect(() => {
    const userRole = localStorage.getItem('doosra-biz-user-role');
    const orgId = localStorage.getItem('doosra-biz-organisation-id');
    const isFreeTrial = localStorage.getItem('is_free_trial_enabled');
    setIsFreeTrail(isFreeTrial ? isFreeTrial === 'true' : false);

    // Simulating any API call or necessary setup on mount
    const params = {
      organisation_id: orgId,
      webhook_type: [
        'CALL',
        'OUTGOING_CALL',
        'VOICE_API',
        'NUMBER_MASKING',
        'NM_CALL_DETAILS',
      ],
    };

    // Replace this block with the actual API call or initialization logic
    console.log('Parameters initialized:', params);
  }, []);

  const logoutHandler = async () => {
    setIsLoading(true);
    try {
      const userRole = localStorage.getItem('doosra-biz-user-role');

      if (userRole === 'USER') {
        if (isRecoveryXEnabled || isCampaignEnabled) {
          await handleAgentActiveStatus({ status: 'offline', logout: true });
        }
        if (localStorage.getItem('is_dialer_widget_enabled') === 'true') {
          await handleDialerAgentStatus('Offline');
        }
      }
      const response = await DELETE(`${userUrl}v1/auth/logout`);
      if (response?.data?.response?.user_id) {
        localStorage.clear();
        sessionStorage.clear();
        window.location.reload(); // This reloads the app and clears state
      }
    } catch (error) {
      console.error('Logout failed:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const MainMenuOptions = [
    {
      name: 'Call logs',
      isVisible: true,
      isActive: true,
      linkedUrl: '/call-logs',
      icon: phone,
    },
    {
      name: 'Numbers',
      isVisible: true,
      isActive: false,
      linkedUrl: '/virtual-numbers',
      icon: schema,
    },
    {
      name: 'Agents',
      isVisible: true,
      isActive: false,
      linkedUrl: '/users',
      icon: recordVoiceOver,
    },
    {
      name: 'Contacts',
      isVisible: true,
      isActive: false,
      linkedUrl: '/contacts',
      icon: schema,
    },
    {
      name: 'Caller Tunes',
      isVisible: true,
      isActive: false,
      linkedUrl: '/caller-tunes',
      icon: schema,
    },
    {
      name: 'Call Routing',
      isVisible: true,
      isActive: false,
      linkedUrl: '/ivr',
      icon: schema,
    },
    {
      name: 'WebHooks',
      isVisible: true,
      isActive: false,
      linkedUrl: '/outgoing-call-webhook',
      icon: schema,
    },
    {
      name: 'Auto Attendant',
      isVisible: true,
      isActive: false,
      linkedUrl: '/auto-attended',
      icon: schema,
    },
    {
      name: 'Call Center',
      isVisible: true,
      isActive: false,
      linkedUrl: '/dashboard',
      icon: schema,
    },
    {
      name: 'Analytics',
      isVisible: true,
      isActive: false,
      linkedUrl: '/analytics',
      icon: schema,
    },
    {
      name: 'Campaigns',
      isVisible: true,
      isActive: false,
      linkedUrl: '/campaigns-v2',
      icon: schema,
    },
    {
      name: 'Number Masking',
      isVisible: true,
      isActive: false,
      linkedUrl: '/number-masking-call-logs',
      icon: schema,
    },
    {
      name: 'Settings',
      isVisible: true,
      isActive: false,
      linkedUrl: '/account-settings',
      action: 'toggleSettingsMenu',
      callback: () => {
        setSettingsMenuOpen(true);
      },
      icon: widgets,
    },
    {
      name: 'Logout',
      isVisible: true,
      isActive: false,
      action: 'logout',
      callback: logoutHandler,
      icon: widgets,
    },
  ];

  const updatedMenuOptions = MainMenuOptions.map((item) => ({
    ...item,
    isActive: location.pathname === item.linkedUrl,
  }));

  const handleMenuClick = (item) => {
    console.log('hendleMenuclick', item);

    if (item.action === 'toggleSettingsMenu') {
      setSettingsMenuOpen(!settingsMenuOpen); // Toggle submenu visibility
    } else {
      setSettingsMenuOpen(false); // Close submenu for other buttons
    }

    if (item.linkedUrl) {
      history.push(item.linkedUrl);
    }
  };

  return (
    <div className={`${className}`}>
      <div style={{ display: 'flex', height: 'calc(100vh - 72px)' }}>
        <div className="Sidebar">
          {updatedMenuOptions.map((item) => (
            <SidebarMainButton
              icon={item.icon}
              key={Math.random()}
              name={item.name}
              isActive={item.isActive}
              isVisible={item.isVisible}
              linkedUrl={item.linkedUrl}
              callback={() => handleMenuClick(item)}
            />
          ))}
        </div>
        {isLoading && <PreLoader />}
        {settingsMenuOpen && <SettingsMenu />}
      </div>
    </div>
  );
};

export default styled(withRouter(Sidebar))`
  .ParentContainer {
    display: grid;
    grid-template-columns: 384px auto;
  }

  // control initial sidebar
  .Sidebar {
    margin: 0;
    padding: 0;
    height: calc(100vh - 100px);
    width: 96px;
    // background-color: ${(props) => props.theme.colors.sidebarBgColor};
    background-color: #f6f6f6;
    height: -webkit-fill-available;
    overflow: auto;
    transition: width 0.3s;
    border-right: 1px solid #eaeaea;
  }

  .SidebarMainButtonHolder {
    display: flex;
    flex-direction: column;
    max-width: 64px;
    margin: 16px;
  }

  .SidebarMainButton {
    background-color: #ffffff;
    height: 64px;
    width: 64px;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    box-shadow: 0px 1px 1.6px 0px #00000029;

  }
  .SidebarMainButtonActive {

    height: 64px;
    width: 64px;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 1px 1.6px 0px #00000029;
    background-color: #F8153B;
  }

  .SidebarMainButtonText {
    text-align: center;
    font-family: Montserrat;
    font-size: 12px;
    font-weight: 600;
    // line-height: 14.63px;
    letter-spacing: -0.02em;
    margin: 0px;
  }
.SidebarMainButtonTextActive{
 text-align: center;
    font-family: Montserrat;
    font-size: 12px;
    font-weight: 600;
    // line-height: 14.63px;
    letter-spacing: -0.02em;
    margin: 0px;
    color: #F8153B;
}

  .SubMenuSideBar {
    
    height: ;calc(100vh - 72px);
    width: 311px;
    background-color: #f6f6f6;
    padding: 24px;
  }
  .SubMenuSidebarTitle {
    font-family: Montserrat;
    font-size: 24px;
    font-weight: 700;
    line-height: 29.26px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #f8153b;
  }
  .SubMenuSidebarSubTitle {
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 600;
    line-height: 19.5px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #9b9b9b;
  }
  .SubMenuSidebarButton {
    width: 263px;
    height: 53px;
    padding: 14px;
    border-radius: 6px;
    background-color: #ffffff;
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 500;
    line-height: 19.5px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    box-shadow: 0px 2px 2px 0px #00000029;
    cursor: pointer;
  }
  .SubMenuSidebarButtonGroup {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-bottom: 32px;
  }
`;
