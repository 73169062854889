import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { commonStyle } from '../../../assets/css/css';

import '../../../assets/css/Content.css';
import { GET } from '../../../services/HttpRequests';
import AudioPlayer from '../components/AudioPlayer';
import Paginator from '../../../common/component/pagination/PaginationComponent';
import PreLoader from '../../../common/component/preLoader/PreLoader';
import SearchBar from '../../../common/component/searchbar/SearchBarComponent';
import Sidebar from '../../../common/componentV2/sidebar/Sidebar';
import Topbar from '../../../common/component/topbar/Topbar';
import TabSearchBar from '../../../common/component/TabSearchbar/TabSearchBarComponent';
import DataTable from '../../../common/component/table/DataTable';
import ViewContactPopup from '../../contacts/component/ViewContactPopup';
import AddContactNotesTagsPopup from '../../contacts/component/AddContactNotesTagsPopup';
import { isSuperAdminWithPermission } from '../../../utility/utility';
import TagIcon from '../../../assets/images/tag.png';
import AICallAnalysisPopup from '../../../common/component/callAiPopup/index';
import PlanExpired from '../../../common/component/plan-expired/PlanExpired';
import { logPostHogEvent } from '../../../common/posthog/posthog';
import moment from 'moment';

const outgoingCallsUrl = process.env.REACT_APP_OUTGOING_CALLS_API;
const incomingCallsUrl = process.env.REACT_APP_INCOMING_CALLS_API;

const CallLogsPage = ({ className }) => {
  const [state, setState] = useState({
    count: 0,
    outgoingCallLogCount: 0,
    didCallBackCount: 0,
    blackListCount: 0,
    totalPages: 0,
    currentPage: 1,
    pageSize: 15,
    callList: [],
    loading: false,
    searchData: {},
  });

  const {
    sidebarClosed,
    callForwardingStatus,
    isOrgOutboundCallEnabled,
    isOutboundCallEnabled,
    isUnansweredAgentEnabled,
  } = useSelector((state) => ({
    sidebarClosed: state.sidebar.sidebarClosed,
    callForwardingStatus: state.topbar.call_forwarding_status,
    isOrgOutboundCallEnabled: state.topbar.isOrgOutboundCallEnabled,
    isOutboundCallEnabled: state.topbar.isOutboundCallEnabled,
    isUnansweredAgentEnabled: state.topbar.isUnansweredAgentEnabled,
  }));

  const getFreshList = useCallback(() => {
    setState((prevState) => ({ ...prevState, loading: true }));
    const data = {
      pageNo: state.currentPage,
      pageSize: state.pageSize,
      organisation_id: localStorage.getItem('doosra-biz-organisation-id'),
    };

    GET(`${incomingCallsUrl}v1/organisations/calls`, data)
      .then((res) => res.data.response)
      .then((res) => {
        const count = Math.max(
          1,
          Math.ceil(res.payload.count / state.pageSize)
        );
        const data = res.payload.docs.map((item) => {
          if (item.notesObj) {
            item.notes = item.notesObj.map((obj) => obj.note).join(', ');
          }
          if (item.tagsObj) {
            item.tags = item.tagsObj.map((obj) => obj.tag);
          }
          return item;
        });

        setState((prevState) => ({
          ...prevState,
          count: res.payload.count,
          callList: data,
          totalPages: count,
          pageSize: res.pageSize,
          loading: false,
          searchData: {},
        }));
      })
      .catch(() => setState((prevState) => ({ ...prevState, loading: false })));
  }, [state.currentPage, state.pageSize]);

  useEffect(() => {
    getFreshList();
    getOutgoingCallLogCount();
  }, [getFreshList]);

  const getOutgoingCallLogCount = useCallback(() => {
    const orgId = localStorage.getItem('doosra-biz-organisation-id');
    GET(`${outgoingCallsUrl}v1/organisations/outbound-call/call-log/list`, {
      pageNo: 1,
      pageSize: 10,
      organisationId: orgId,
    })
      .then((res) => res.data.response)
      .then((res) =>
        setState((prevState) => ({
          ...prevState,
          outgoingCallLogCount: res.metadata.total,
        }))
      )
      .catch(() => null);
  }, []);

  const fetchPageData = (value) => {
    const data = {
      pageNo: value,
      pageSize: state.pageSize,
      organisation_id: localStorage.getItem('doosra-biz-organisation-id'),
      ...state.searchData,
    };

    GET(`${incomingCallsUrl}v1/organisations/calls`, data)
      .then((res) => res.data.response)
      .then((res) => {
        const data = res.payload.docs.map((item) => {
          if (item.notesObj) {
            item.notes = item.notesObj.map((obj) => obj.note).join(', ');
          }
          if (item.tagsObj) {
            item.tags = item.tagsObj.map((obj) => obj.tag);
          }
          return item;
        });

        setState((prevState) => ({
          ...prevState,
          count: res.payload.count,
          callList: data,
          currentPage: value,
          pageSize: res.pageSize,
        }));
      })
      .catch((err) => console.error(err));
  };

  const trimNumber = (number) =>
    number && number.length === 12 ? number.substring(2) : number;

  const tabs = [];
  if (callForwardingStatus)
    tabs.push({
      title: 'Incoming',
      subtitle: `${state.count}`,
      onClick: () => {
        logPostHogEvent('INCOMING_TAB');
        window.location.href = '/call-logs';
      },
      active: true,
    });

  if (isOutboundCallEnabled && isOrgOutboundCallEnabled)
    tabs.push({
      title: 'Outgoing',
      subtitle: `${state.outgoingCallLogCount}`,
      onClick: () => {
        logPostHogEvent('OUTGOING_TAB');
        window.location.href = '/outgoing-call-logs';
      },
    });

  return (
    <div className={className}>
      <Topbar />
      <div style={{ display: 'flex', height: 'calc(100vh - 72px)' }}>
        <Sidebar />
        {/* <div style={{ width: '200px', height: '200px' }}>Test</div> */}
        {/* <div className={sidebarClosed ? 'Content' : 'ContentFull'}> */}
        <div className="ContentContainer">
          <TabSearchBar tabs={tabs}>
            <SearchBar
              type="calls"
              disableTitle
              count={state.count}
              reset={getFreshList}
              search={(searchItem) => {
                setState((prevState) => ({
                  ...prevState,
                  searchData: searchItem,
                }));
                getFreshList();
              }}
            />
          </TabSearchBar>

          <Paginator
            currentPage={state.currentPage}
            lastPage={state.totalPages}
            getInfo={fetchPageData}
          />
        </div>
      </div>
      {/* </div> */}
    </div>
  );
};

export default commonStyle(CallLogsPage);
