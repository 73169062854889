import React from "react";
import AlohaaLogo from '../../assets/images/alohaaLogo.svg';
import Person from '../../assets/images/personWhite.png';
import Profile from '../../common/component/Profile/Profile';


const CallFlowHeader = () => {
  return (
    <div className="CallFlowHeader">
        <button className="UnStyledButton">
          <img className="CallFlowHeaderLogo" style={{ marginLeft: "34px" }} alt="" src={AlohaaLogo} />
        </button>
        <Profile></Profile>
    </div>
  )
};

export default CallFlowHeader;