import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import {
  changeTimeFormat,
  displayUserCallBalance,
  enableFeaturesByOrg,
} from '../../../utility/utility';

import { DELETE, GET, PUT } from '../../../services/HttpRequests';
// import CallForwardToggles, { Toggle } from '../component/CallForwardToggles';
import DeleteUserPopup from '../component/DeleteUserPopup';
import EditUserPopup from '../component/EditUserPopup';
import CallSettingsPopup from '../component/CallSettingsPopup';
import CallBalance from '../component/AssignCallBalance';
import Paginator from '../../../common/component/pagination/PaginationComponent';
import PreLoader from '../../../common/component/preLoader/PreLoader';
import SearchBar from '../../../common/component/searchbar/SearchBarComponent';
import Sidebar from '../../../common/componentV2/sidebar/Sidebar';
import Topbar from '../../../common/component/topbar/Topbar';
import UnlockUserPopup from '../../../pages/Users/component/UnlockUserPopup';
import DataTable from '../../../common/component/table/DataTable';
import { isSuperAdminWithPermission } from '../../../utility/utility';
import { commonStyle } from '../../../assets/css/css';
import ViewPlan from '../../../common/component/freeTrial/ViewPlan';
import {
  ADMIN_SEARCH_AGENTS,
  ADMIN_AGENT_OUTGOING_TOGGLE,
  ADMIN_AGENT_INCOMING_TOGGLE,
  ADMIN_AGENT_AVAILABILITY_TOGGLE,
  ADMIN_AGENT_SMS_DELETION_TOGGLE,
  ADMIN_AGENT_UPDATE_CALL_BALANCE_BUTTON,
} from '../../../common/posthog/events';
import { logPostHogEvent } from '../../../common/posthog/posthog';

const userUrl = process.env.REACT_APP_USER_API;
const outgoingCallsUrl = process.env.REACT_APP_OUTGOING_CALLS_API;
const incomingCallsUrl = process.env.REACT_APP_INCOMING_CALLS_API;
const MessageUrl = process.env.REACT_APP_MESSAGE_API;
class UsersPage extends Component {
  constructor() {
    super();
    this.state = {
      usersList: [],
      count: 0,
      totalPages: 0,
      pageSize: 15,
      currentPage: 1,
      editUserError: '',
      loading: false,
      searchData: {},
      managersData: [],
    };
  }

  async fetchManagers() {
    try {
      const res = await GET(`${userUrl}v1/admin/get-managers`, {
        organisationId: localStorage.getItem('doosra-biz-organisation-id'),
      });
      this.setState({ managersData: res?.data?.response });
    } catch (e) {
      toast.error('Error in fetching managers');
      this.setState({ managersData: [] });
    }
  }

  componentDidMount() {
    this.getFreshList();
    this.fetchManagers();
  }

  getFreshList = () => {
    this.setState({ loading: true });
    let orgId = localStorage.getItem('doosra-biz-organisation-id');
    let data = {
      pageNo: this.state.currentPage,
      pageSize: this.state.pageSize,
      organisationId: orgId,
    };
    GET(userUrl + `v1/user`, data)
      .then((res) => {
        return res.data.response;
      })
      .then((res) => {
        this.setState({
          usersList: res.users.docs,
          count: res.users.count,
          totalPages: Math.ceil(res.users.count / this.state.pageSize),
          pageSize: res.pageSize,
          loading: false,
          searchData: {},
        });
      })
      .catch((err) => {
        if (err) {
          this.setState({ loading: false });
        }
      });
  };

  updateCallBalance = (userData, data) => {
    logPostHogEvent(ADMIN_AGENT_UPDATE_CALL_BALANCE_BUTTON);
    return PUT(
      `${userUrl}v1/user/add-minute-balance`,
      {},
      {
        organisationId: userData.organisation?.organisation_id,
        zvrMobileNo: userData.zvr_mobile_no,
        callBalance: parseInt(data.addBalance),
        clearCallBalance: data.clearCallBalance,
        userId: userData._id,
        adminUserId: localStorage.getItem('doosra-biz-user-id'),
        adminUserName: localStorage.getItem('doosra-biz-user-name'),
      }
    )
      .then((res) => {
        return res.data.response;
      })
      .then((res) => {
        this.setState({ editCallBalanceError: '' });
        this.getFreshList();
        return true;
      })
      .catch((err) => {
        this.setState({ editCallBalanceError: err.response.data.error.reason });
        throw err.response.data.error.reason;
      });
  };
  seachHandler = (data) => {
    logPostHogEvent(ADMIN_SEARCH_AGENTS);
    let orgId = localStorage.getItem('doosra-biz-organisation-id');
    data.pageNo = 1;
    data.pageSize = this.state.pageSize;
    data.organisationId = orgId;
    GET(userUrl + `v1/user`, data)
      .then((res) => {
        return res.data.response;
      })
      .then((res) => {
        this.setState({
          usersList: res.users.docs,
          count: res.users.count,
          totalPages: Math.ceil(res.users.count / this.state.pageSize),
          pageSize: res.pageSize,
          searchData: {},
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  fetchPageData = (value) => {
    let orgId = localStorage.getItem('doosra-biz-organisation-id');
    let data = {
      pageNo: value,
      pageSize: this.state.pageSize,
      organisationId: orgId,
    };
    data = { ...data, ...this.state.searchData };
    GET(userUrl + `v1/user`, data)
      .then((res) => {
        return res.data.response;
      })
      .then((res) => {
        this.setState({
          usersList: res.users.docs,
          count: res.users.count,
          currentPage: value,
          pageSize: res.pageSize,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  callForwardingStatusHandler = (data) => {
    logPostHogEvent(ADMIN_AGENT_INCOMING_TOGGLE);
    PUT(`${incomingCallsUrl}v1/organisations/call-forwarding`, {
      ...data,
      organisation_id: localStorage.getItem('doosra-biz-organisation-id'),
    })
      .then((res) => {
        return res.data.response.doc;
      })
      .then((res) => {
        let newList = [...this.state.usersList];
        let index = newList.findIndex((obj) => obj.user_id === data.user_id);
        newList[index].organisation.is_call_forwarding_allowed =
          data.is_call_forwarding_allowed;
        newList[index].organisation.is_call_forwarding =
          data.is_call_forwarding_allowed;
        this.setState({ usersList: newList });
      })
      .catch((err) => {
        console.log({ err });
      });
  };

  changeOutboundCallStatus = (profile) => (value) => {
    logPostHogEvent(ADMIN_AGENT_OUTGOING_TOGGLE);
    let orgId = localStorage.getItem('doosra-biz-organisation-id');
    PUT(
      `${outgoingCallsUrl}v1/organisations/outbound-call/user/status`,
      {},
      {
        userId: profile.user_id,
        isOutboundCallEnabled: value,
        organisationId: orgId,
      }
    )
      .then((res) => res.data.response.data)
      .then((res) => this.getFreshList())
      .catch((err) => {});
  };

  // changeSMSDeletionStatus = (profile) => (value) => {
  //   logPostHogEvent(ADMIN_AGENT_SMS_DELETION_TOGGLE);
  //   let orgId = localStorage.getItem('doosra-biz-organisation-id');
  //   PUT(
  //     `${MessageUrl}v1/organisations/delete/sms/status`,
  //     {},
  //     {
  //       userId: profile.user_id,
  //       isSMSDeletionEnabled: value,
  //       organisationId: orgId,
  //     }
  //   )
  //     .then((res) => res.data.response.data)
  //     .then((res) => this.getFreshList());
  //   //   .catch((err) => {});
  // };

  callForwardingStateHandler = (data) => {
    logPostHogEvent(ADMIN_AGENT_AVAILABILITY_TOGGLE);
    PUT(`${incomingCallsUrl}v1/organisations/call-forwarding-state`, {
      ...data,
      organisation_id: localStorage.getItem('doosra-biz-organisation-id'),
    })
      .then((res) => res.data.response.data)
      .then((res) => {
        let newList = [...this.state.usersList];
        let index = newList.findIndex((obj) => obj.user_id === data.user_id);
        newList[index].organisation.is_call_forwarding =
          data.is_call_forwarding;
        this.setState({ usersList: newList });
      })
      .catch((err) => {});
  };

  edtiUserDetailsHandler = (userId, data, callback) => {
    let orgId = localStorage.getItem('doosra-biz-organisation-id');
    PUT(
      `${userUrl}v1/user`,
      {},
      { ...data, userId: userId, organisationId: orgId }
    )
      .then((res) => {
        return res.data.response;
      })
      .then((res) => {
        let newUsersList = this.state.usersList.map((item) => {
          if (item.user_id === res.new_Profile.user_id) {
            item.organisation = res.new_Profile.organisation;
            item.zvr_name = res.new_Profile.zvr_name;
            item.zvr_mobile_no = res.new_Profile.zvr_mobile_no;
            item.zvr_email = res.new_Profile.zvr_email;
            item.startTime = res.new_Profile?.organisation?.startTime;
            item.endTime = res.new_Profile?.organisation?.endTime;
            item.is_webrtc_enabled = res.new_Profile.is_webrtc_enabled;
          }
          return item;
        });

        this.setState({ editUserError: '', usersList: newUsersList });
        callback();
      })
      .catch((err) => {
        this.setState({ editUserError: err.response.data.error.reason });
        callback();
      });
  };
  deleteUserHandler = (userId) => {
    let orgId = localStorage.getItem('doosra-biz-organisation-id');
    DELETE(`${userUrl}v1/user`, { organisationId: orgId, userId: userId })
      .then((res) => {
        return res.data.response;
      })
      .then((res) => {
        if (res.user.user_id) {
          let usersList = this.state.usersList.filter(
            (x) => x.user_id !== res.user.user_id
          );
          this.setState({
            usersList,
            count: usersList.length,
          });
        }
      })
      .catch((err) => {
        toast.error(
          `${err?.response?.data?.error?.reason}` || 'An error occured'
        );
      });
  };

  trimNumber = (number) => {
    if (number && number.length == 12) return number.substring(2);
    return number;
  };

  checkUserStatus = (user) => {
    // if (!user.v_mobile_no || user.v_mobile_no === 'NA') return '-';
    // if (user.v_mobile_no) {
    if (user?.organisation?.is_onboard) return 'ACTIVE';
    if (!user?.organisation?.is_onboard) return 'INACTIVE';
    // }
  };

  resendSignupInvite = async (user) => {
    let orgId = localStorage.getItem('doosra-biz-organisation-id');
    await PUT(
      `${userUrl}v1/user/resend-signup-invite`,
      {},
      { organisationId: orgId, userId: user.user_id }
    );
    toast.success(`Invite successfully sent to ${user.zvr_name}`);
  };

  isLocked = (item) =>
    (item.organisation && item.organisation.has_locked) || item?.has_locked;

  render() {
    let displayData;
    if (this.state.loading) {
      displayData = <PreLoader />;
    }
    const {
      callForwardingStatus,
      isOrgOutboundCallEnabled,
      isWebrtcEnabled,
      isCCWebrtcEnabled,
    } = this.props;
    if (this.state.usersList.length > 0) {
      displayData = this.state.usersList.map((user) => (
        <DataTable.Row key={user.user_id}>
          <DataTable.Cell style={{ padding: 5 }}>
            {moment(user.created_at).format('DD/MM/YYYY')}
          </DataTable.Cell>
          <DataTable.Cell style={{ padding: 5 }}>
            {user.zvr_name}
          </DataTable.Cell>
          <DataTable.Cell style={{ padding: 5 }}>
            {!user.v_mobile_no || user.v_mobile_no === 'NA'
              ? '-'
              : this.trimNumber(user.v_mobile_no)}
          </DataTable.Cell>
          <DataTable.Cell style={{ padding: 5 }}>
            {user.zvr_mobile_no}
          </DataTable.Cell>
          {enableFeaturesByOrg() && (
            <>
              <DataTable.Cell style={{ padding: 5 }}>
                {changeTimeFormat(user?.organisation.startTime)}
              </DataTable.Cell>
              <DataTable.Cell style={{ padding: 5 }}>
                {changeTimeFormat(user?.organisation.endTime)}
              </DataTable.Cell>
              <DataTable.Cell style={{ padding: 5 }}>
                {displayUserCallBalance(
                  user.organisation?.call_minutes_available
                )}
              </DataTable.Cell>
            </>
          )}

          <DataTable.Cell style={{ padding: 5 }}>
            {this.isLocked(user) ? (
              <UnlockUserPopup
                user_id={user.user_id}
                lock
                onSuccess={() => this.fetchPageData(1)}
              />
            ) : this.checkUserStatus(user) === 'INACTIVE' ? (
              !isSuperAdminWithPermission('ADD') ? (
                <div>Resend Invite</div>
              ) : (
                <a
                  style={{ color: '#196cca' }}
                  onClick={() => this.resendSignupInvite(user)}
                  href="#"
                >
                  Resend Invite
                </a>
              )
            ) : (
              this.checkUserStatus(user)
            )}
          </DataTable.Cell>
          {/* {isOrgOutboundCallEnabled && (
            <DataTable.Cell >
              <Toggle
                value={user.isOutboundCallEnabled}
                onChange={this.changeOutboundCallStatus(user)}
              />
            </DataTable.Cell>
          )}
          {callForwardingStatus && (
            <CallForwardToggles
              masterCallForwarding={callForwardingStatus}
              userData={user}
              forwardingStatus={user.organisation.is_call_forwarding_allowed}
              forwardingState={user.organisation.is_call_forwarding}
              callForwardingStatusHandler={(val) =>
                this.callForwardingStatusHandler(val)
              }
              callForwardingStateHandler={(val) =>
                this.callForwardingStateHandler(val)
              }
              changeOutboundCallStatus={(val) =>
                this.changeOutboundCallStatus(val)
              }
            />
          )} */}
          <DataTable.Cell style={{ padding: 5 }}>
            {user?.managerName || '-'}
          </DataTable.Cell>

          <DataTable.Cell style={{ padding: 0, paddingRight: 3 }}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <CallSettingsPopup
                isOrgOutboundCallEnabled={isOrgOutboundCallEnabled}
                callForwardingStatus={callForwardingStatus}
                callForwardingStatusHandler={this.callForwardingStatusHandler}
                callForwardingStateHandler={this.callForwardingStateHandler}
                changeOutboundCallStatus={this.changeOutboundCallStatus}
                // changeSMSDeletionStatus={this.changeSMSDeletionStatus}
                user={user}
                className="EnDisToggleHolder"
              />
              {isSuperAdminWithPermission('EDIT') && enableFeaturesByOrg() && (
                <CallBalance
                  user={user}
                  updateCallBalance={this.updateCallBalance}
                  error={this.state.editCallBalanceError}
                />
              )}
              {isSuperAdminWithPermission('EDIT') && (
                <EditUserPopup
                  key={user.user_id}
                  managersData={this.state.managersData || []}
                  isWebrtcEnabled={isWebrtcEnabled}
                  isCCWebrtcEnabled={isCCWebrtcEnabled}
                  isWebRtcCallSettingEnabled={
                    localStorage.getItem('webrtc_enabled') === 'true'
                      ? true
                      : false
                  }
                  is_webrtc_enabled={user.is_webrtc_enabled}
                  emp_id={user?.organisation?.emp_id}
                  userId={user.user_id}
                  email={user.zvr_email}
                  name={user.zvr_name}
                  editUserError={this.state.editUserError}
                  userNumber={user.zvr_mobile_no}
                  userDoosraNumber={user.v_mobile_no}
                  startTime={user?.organisation?.startTime}
                  endTime={user?.organisation?.endTime}
                  managerId={user?.manager_id}
                  editUser={(id, data, callback) =>
                    this.edtiUserDetailsHandler(id, data, callback)
                  }
                />
              )}
              {isSuperAdminWithPermission('DELETE') && (
                <DeleteUserPopup
                  key={user.user_id + user.zvr_name}
                  userId={user.user_id}
                  name={user.zvr_name}
                  group={user.group}
                  userNumber={user.zvr_mobile_no}
                  userDoosraNumber={user.v_mobile_no}
                  deleteUser={(id) => this.deleteUserHandler(id)}
                />
              )}
            </div>
          </DataTable.Cell>
        </DataTable.Row>
      ));
    }
    return (
      <div className={`${this.props.className}`}>
        <Topbar />
        <div style={{ display: 'flex', height: 'calc(100vh - 72px)' }}>
          <Sidebar />
          <div className="ContentContainer">
            <SearchBar
              type="users"
              count={this.state.count}
              search={this.seachHandler}
              reset={this.getFreshList}
              refreshList={this.getFreshList}
              basicUserDownloadDetail={this.state.usersList}
              isWebrtcEnabled={isWebrtcEnabled}
              managersData={this.state.managersData}
              isWebRtcCallSettingEnabled={
                localStorage.getItem('webrtc_enabled') === 'true' ? true : false
              }
              isCCWebrtcEnabled={isCCWebrtcEnabled}
            />
            <DataTable.Table>
              <DataTable.Head>
                <DataTable.CellHead style={{ width: '10%' }}>
                  ADDED ON
                </DataTable.CellHead>
                <DataTable.CellHead style={{ width: '20%' }}>
                  NAME
                </DataTable.CellHead>
                <DataTable.CellHead style={{ width: '12%' }}>
                  VIRTUAL NO
                </DataTable.CellHead>
                <DataTable.CellHead style={{ width: '12%' }}>
                  PERSONAL NO
                </DataTable.CellHead>
                {enableFeaturesByOrg() && (
                  <>
                    <DataTable.CellHead style={{ width: '12%' }}>
                      START TIME
                    </DataTable.CellHead>
                    <DataTable.CellHead style={{ width: '15%' }}>
                      END TIME
                    </DataTable.CellHead>
                    <DataTable.CellHead style={{ width: '8%' }}>
                      CALL BALANCE
                    </DataTable.CellHead>
                  </>
                )}

                <DataTable.CellHead style={{ width: '15%' }}>
                  STATUS
                </DataTable.CellHead>
                <DataTable.CellHead style={{ width: '15%' }}>
                  MANAGER NAME
                </DataTable.CellHead>
                {/* {isOrgOutboundCallEnabled && (
                  <DataTable.CellHead>
                    OUTGOING CALLS
                  </DataTable.CellHead>
                )}
                {callForwardingStatus && (
                  <DataTable.CellHead>
                    CALL FORWARDING
                  </DataTable.CellHead>
                )}
                {callForwardingStatus && (
                  <DataTable.CellHead>
                    CURRENT STATE
                  </DataTable.CellHead>
                )} */}
                <DataTable.CellHead style={{ width: '15%' }}>
                  MANAGER NAME
                </DataTable.CellHead>
                <DataTable.CellHead style={{ width: '20%' }}>
                  ACTIONS
                </DataTable.CellHead>
              </DataTable.Head>
              <DataTable.Body>{displayData}</DataTable.Body>
            </DataTable.Table>
            <Paginator
              currentPage={this.state.currentPage}
              lastPage={this.state.totalPages}
              getInfo={(value) => this.fetchPageData(value)}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  sidebarClosed: state.sidebar.sidebarClosed,
  callForwardingStatus: state.topbar.call_forwarding_status,
  isOrgOutboundCallEnabled: state.topbar.isOrgOutboundCallEnabled,
  isWebrtcEnabled: state.topbar.isWebrtcEnabled,
  isCCWebrtcEnabled: state.topbar.isCCWebrtcEnabled,
});

export default commonStyle(connect(mapStateToProps, {})(UsersPage));
