/* eslint-disable */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, Redirect, withRouter } from 'react-router-dom';
import * as onbaordingTourConstants from '../../../assets/onbaordingTourConstants';
import {
  toggleSidebar,
  setSMSAccessKey,
  setCallAccessKey,
} from '../../../actions/sidebarActions';
import styled from '@emotion/styled';
import '../../../assets/css/Sidebar.css';
import burgerMenu from '../../../assets/images/burgerMenuWhite.png';
import AlohaaLogo from '../../../assets/images/alohaaLogoAndWhiteText.svg';
import keypadWhite from '../../../assets/images/keypadWhite.png';
import logoutWhite from '../../../assets/images/logoutWhite.png';
import personWhite from '../../../assets/images/personWhite.png';
import phoneForward from '../../../assets/images/phoneForward.png';
import phoneWhite from '../../../assets/images/phoneWhite.png';
import numberMaskingIcon from '../../../assets/images/numberMaskingIcon.png';
import bullsEye from '../../../assets/images/bullseye.png';
import analytics from '../../../assets/images/analytics.svg';
import settingsWhite from '../../../assets/images/settingsWhite.png';
import smsWhite from '../../../assets/images/smsWhite.png';
import toggleOff from '../../../assets/images/toggleOff.png';
import toggleOn from '../../../assets/images/toggleOn.png';
import webhookLogo from '../../../assets/images/webhook.png';
import verifiedContact from '../../../assets/images/icon-right-white.svg';
import campaign from '../../../assets/images/campaign.svg';
// import Joyride from 'react-joyride';
import {
  RoutingSVG,
  SmallMusicIcon,
  VoiceBroadcastIcon,
  MessageBroadcastIcon,
} from '../../../assets/Icons/Icons';
import contactsIcon from '../../../assets/images/contactstab.png';
import autoAttendantIcon from '../../../assets/images/autoAttendantWhite.png';
import upgradeLockIcon from '../../../assets/images/upgrade_lock_icon.svg';
import { DELETE, GET, POST, PUT } from '../../../services/HttpRequests';
import NumberDialer from '../number-dialer/NumberDialer';
import { saveUserProfileDetails } from '../../../actions/topBarActions';
import {
  EndRun,
  startRun,
  increaseStepIndex,
  setStepIndexToZero,
  decreaseStepIndex,
} from '../../../actions/onBoardingTourActions';
import { toast } from 'react-toastify';
import PreLoader from '../preLoader/PreLoader';
import { isSuperAdminWithPermission } from '../../../utility/utility';
import { SIDEBAR_BUTTON, AVAILABILITY_TOGGLE } from '../../posthog/events';
import { logPostHogEvent } from '../../posthog/posthog';
import codeIcon from '../../../assets/images/code.png';
import Callflow from '../../../assets/images/schema.png';

const userUrl = process.env.REACT_APP_USER_API;
const incomingCallsUrl = process.env.REACT_APP_INCOMING_CALLS_API;
const organizationUrl = process.env.REACT_APP_ORGANIZATION_API;
const webRTCOutGoingUrl = process.env.REACT_APP_WEBRTC_RECOVERY_API_URL;
const cloudCallUrl = process.env.REACT_APP_CLOUD_CALL_CENTER;

const isSuperAdmin =
  localStorage.getItem('doosra-biz-user-role-is-super-admin') === 'true';

class Sidebar extends Component {
  constructor() {
    super();
    this.state = {
      pathname: '',
      callForwardingAllowed: false,
      isOutboundCallEnabled: false,
      callForwarding: false,
      isSMSApiAvailable: false,
      isCallApiAvailable: false,
      isVoiceApiAvailable: false,
      isNumberMaskingEnabled: false,
      isLoading: false,
      isFreeTrail: true,
    };
  }

  switchState = () => {
    logPostHogEvent(SIDEBAR_BUTTON);
    this.props.toggleSidebar();
  };

  componentDidMount() {
    if (window?.outerWidth < 1250) {
      this.props.toggleSidebar();
    }
    this.setState({ pathname: window.location.pathname });
    let orgId = localStorage.getItem('doosra-biz-organisation-id');
    GET(userUrl + `v1/user/profile`, { organisationId: orgId })
      .then((res) => {
        return res.data.response.result;
      })
      .then((res) => {
        if (res?.is_sms_deletion_enabled) {
          localStorage.setItem(
            'doosra-biz-user-deletion-enabled',
            res.is_sms_deletion_enabled
          );
        } else {
          localStorage.setItem('doosra-biz-user-deletion-enabled', false);
        }
        if (res.apiKey)
          localStorage.setItem('doosra-biz-org-apiKey', res.apiKey);
        if (res.name) localStorage.setItem('doosra-biz-user-name', res.name);
        if (res.email) localStorage.setItem('doosra-biz-user-email', res.email);
        if (res.phone_number)
          localStorage.setItem('doosra-biz-user-number', res.phone_number);

        let isFreeTrail = localStorage.getItem('is_free_trial_enabled');
        isFreeTrail = isFreeTrail
          ? isFreeTrail === 'true'
            ? true
            : false
          : false;
        if (isFreeTrail) {
          localStorage.setItem('admin-contact-number', res?.admin_number);
        }
        this.setState({
          callForwardingAllowed:
            res?.organisation?.is_call_forwarding_allowed || false,
          callForwarding: res?.organisation?.is_call_forwarding || false,
          isOutboundCallEnabled: res?.isOutboundCallEnabled || false,
          isFreeTrail: isFreeTrail,
        });

        this.props.saveUserProfileDetails({
          ...res,
          isCallForwardingAllowed:
            res?.organisation?.is_call_forwarding_allowed || false,
          user_call_minutes_available:
            res?.organisation?.call_minutes_available,
          user_startTime: res?.organisation?.startTime || '',
          user_endTime: res?.organisation?.endTime || '',
          vmnExpire: res?.organisation?.vmnExpireDate || '',
        });
      })
      .catch((err) => {
        console.log(err);
      });

    let params = {
      organisation_id: orgId,
      webhook_type: [
        'CALL',
        'OUTGOING_CALL',
        'VOICE_API',
        'NUMBER_MASKING',
        'NM_CALL_DETAILS',
      ],
    };
    GET(`${organizationUrl}v1/organisation/web-hook`, params)
      .then((res) => {
        return res.data.response;
      })
      .then((res) => {
        const { features, add_ons } = res.webHookData;
        if (features && features.sms && add_ons?.is_sms_webhook_enabled) {
          const { is_sms_webhook_enabled } = add_ons;
          this.setState({ isSMSApiAvailable: is_sms_webhook_enabled });
          localStorage.setItem(
            'is_sms_webhook_enabled',
            is_sms_webhook_enabled
          );
        }
        if (
          features &&
          features.voice_api &&
          add_ons?.is_voice_api_webhook_enabled
        ) {
          const { is_voice_api_webhook_enabled } = add_ons;
          this.setState({ isVoiceApiAvailable: is_voice_api_webhook_enabled });
          localStorage.setItem(
            'is_voice_api_webhook_enabled',
            is_voice_api_webhook_enabled
          );
        }
        const { is_outgoing_call_webhook_enabled } = add_ons;
        this.setState({
          isOutgoingApiAvailable: is_outgoing_call_webhook_enabled,
        });
        localStorage.setItem(
          'is_outgoing_call_webhook_enabled',
          is_outgoing_call_webhook_enabled
        );
        if (features && features.call && add_ons?.is_call_webhook_enabled) {
          const { is_call_webhook_enabled } = add_ons;
          this.setState({ isCallApiAvailable: is_call_webhook_enabled });
          localStorage.setItem(
            'is_call_webhook_enabled',
            is_call_webhook_enabled
          );
        }
        // console.log('res', res);
        // const accessKeys = res.acessKeys
        // if (accessKeys.length > 0) {
        //   accessKeys.map((item) => {
        //     if (item.access_type === "SMS") {
        //       this.setState({ isSMSApiAvailable: true })
        //       this.props.setSMSAccessKey(item.access_key)
        //     }
        //     if (item.access_type === "CALL") {
        //       this.setState({ isCallApiAvailable: true })
        //       this.props.setCallAccessKey(item.access_key)
        //     }
        //     return item
        //   })
        // }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  handleAgentActiveStatus = async (inputData) => {
    console.log(inputData);
    const data = {
      organisationId: localStorage.getItem('doosra-biz-organisation-id'),
      agentId: localStorage.getItem('doosra-biz-user-id'),
      breakSwitch: '',
      breakReason: inputData.status,
    };
    if (inputData.logout) {
      data.logout = inputData.logout;
    }
    if (inputData.status === 'active') {
      data.breakSwitch = 'off';
    } else {
      data.breakSwitch = 'on';
    }
    console.log(data);
    await PUT(webRTCOutGoingUrl + 'metrics/update-agent-break-time', {}, data)
      .then((res) => {
        toast.success(res?.data?.response?.msg);
      })
      .catch((err) => {
        toast.error(err?.response?.data?.error?.reason);
        return false;
      });
  };

  stopBatchCall = () => {
    var data = JSON.stringify({
      batchCallId: this.props.batchId,
      organisationId: localStorage.getItem('doosra-biz-organisation-id'),
      campaignId: this.props.selectedCampaign,
      userId: localStorage.getItem('doosra-biz-user-id'),
    });
    if (this.props.RTCSessionData) {
      this.props.RTCSessionData.terminate();
    }
    return POST(cloudCallUrl + 'cloud-call/stop-batch-call', data)
      .then((res) => {
        console.log('true case');
        return true;
      })
      .catch((err) => {
        toast.error(err?.response?.data?.error?.reason);
        return false;
      });
  };

  handleDialerAgentStatus = async (eventKey) => {
    const breakReasonMap = {
      Break: 'break',
      Online: 'active',
      Busy: 'busy',
      Offline: 'offline',
    };
    let breakReason = breakReasonMap[eventKey] || 'offline';
    const data = {
      organisationId: localStorage.getItem('doosra-biz-organisation-id'),
      agentId: localStorage.getItem('doosra-biz-user-id'),
      breakSwitch: '',
      breakReason,
      logout: true,
    };
    if (eventKey === 'Online') {
      data.breakSwitch = 'off';
    } else {
      data.breakSwitch = 'on';
    }
    if (this.props.selectedCampaign) {
      await this.stopBatchCall();
    }
    await PUT(cloudCallUrl + 'metric/update-agent-break-time', {}, data)
      .then((res) => {
        return true;
      })
      .catch((err) => {
        return false;
      });
  };

  logoutHandler = async () => {
    const { isReocveryXEnabled, isCampaignEnabled } = this.props;
    const userRole = localStorage.getItem('doosra-biz-user-role');
    if (userRole === 'USER') {
      if (isReocveryXEnabled || isCampaignEnabled) {
        await this.handleAgentActiveStatus({
          status: 'offline',
          logout: true,
        });
      }
      if (localStorage.getItem('is_dialer_widget_enabled') === 'true') {
        await this.handleDialerAgentStatus('Offline');
      }
    }
    DELETE(`${userUrl}v1/auth/logout`)
      .then((res) => {
        if (res?.data?.response?.user_id) {
          localStorage.clear();
          sessionStorage.clear();
          window.location.reload();
          this.setState({ isLoading: false });
        }
      })
      .catch((err) => {
        console.log(err);
        this.setState({ isLoading: false });
      });
  };

  callForwardingStateHandler = (data) => {
    logPostHogEvent(AVAILABILITY_TOGGLE);
    data.user_id = localStorage.getItem('doosra-biz-user-id');
    //data.v_mobile_no = localStorage.getItem('doosra-biz-user-doosra-number');
    data.organisation_id = localStorage.getItem('doosra-biz-organisation-id');
    PUT(`${incomingCallsUrl}v1/organisations/call-forwarding-state`, data)
      .then((res) => {
        return res?.data?.response;
      })
      .then((res) => {
        console.log('res', res);
        this.setState({
          callForwarding: res?.doc?.organisation?.is_call_forwarding,
        });
      })
      .catch((err) => {});
  };

  render() {
    let userRole = localStorage.getItem('doosra-biz-user-role');
    let orgId = localStorage.getItem('doosra-biz-organisation-id');
    let isFreeTrail = localStorage.getItem('is_free_trial_enabled');
    isFreeTrail = isFreeTrail ? (isFreeTrail === 'true' ? true : false) : false;
    const {
      callForwardingStatus,
      isOrgOutboundCallEnabled,
      callPriorityRoutingStatus,
      isCallerTuneEnabled,
      isReocveryXEnabled,
      isSmsInboxEnabled,
      isCampaignEnabled,
      isFreeFormEnabled,
      isCloudCallCenterEnabled,
    } = this.props;
    const { isOutboundCallEnabled, isLoading } = this.state;
    const getSideBarButton = (buttonName) => {
      const {
        callPriorityRoutingStatus,
        sidebarClosed,
        isSmsInboxEnabled,
        isReocveryXEnabled,
        isCallerTuneEnabled,
        isOrgOutboundCallEnabled,
        isAutoAttendantEnabled,
        isNumberMaskingEnabled,
        isMessageBroadcastEnabled,
        isVoiceBroadcastEnabled,
        isVoiceApiEnabled,
        isFreeFormEnabled,
        isCloudCallCenterEnabled,
      } = this.props;
      const {
        pathname,
        isSMSApiAvailable,
        isCallApiAvailable,
        isOutgoingApiAvailable,
        isVoiceApiAvailable,
      } = this.state;

      const buttonConfig = {
        // smsInbox: {
        //   iconElement: (
        //     <img alt="" src={smsWhite} className="SidebarItemIcon" />
        //   ),
        //   text: 'SMS Inbox',
        //   path: '/sms-inbox',
        //   isEnabled: isSmsInboxEnabled || !isFreeTrail,
        // },
        callLogs: {
          iconElement: (
            <img alt="" src={phoneWhite} className="SidebarItemIcon" />
          ),
          text: 'Call Logs',
          path:
            (userRole === 'ADMIN' && callForwardingStatus == true) ||
            (userRole === 'USER' && callForwardingStatus == true)
              ? '/call-logs'
              : '/outgoing-call-logs',
          isEnabled:
            (userRole === 'ADMIN' && isOrgOutboundCallEnabled) ||
            (userRole === 'USER' &&
              isOutboundCallEnabled &&
              isOrgOutboundCallEnabled) ||
            (userRole === 'ADMIN' && callForwardingStatus == true) ||
            (userRole === 'USER' && callForwardingStatus == true),
        },
        recoveryX: {
          iconElement: (
            <img alt="" src={bullsEye} className="SidebarItemIcon" />
          ),
          text: 'RecoveryX',
          path: '/recovery-calling',
          isEnabled: isReocveryXEnabled,
        },
        cloudCC: {
          iconElement: (
            <img alt="" src={bullsEye} className="SidebarItemIcon" />
          ),
          text: 'Call Center',
          path: '/dashboard',
          isEnabled: isCloudCallCenterEnabled,
        },
        analytics: {
          iconElement: (
            <img alt="" src={bullsEye} className="SidebarItemIcon" />
          ),
          text: 'Analytics',
          path: '/analytics',
          isEnabled: localStorage.getItem('is_analytics_enabled') === 'true',
        },
        campaigns: {
          iconElement: (
            <img alt="" src={campaign} className="SidebarItemIcon" />
          ),
          text: 'Campaigns',
          path: '/campaigns-v2',
          isEnabled: localStorage.getItem('is_campaigns_v2_enabled') === 'true',
        },
        numberMasking: {
          iconElement: (
            <img alt="" src={numberMaskingIcon} className="SidebarItemIcon" />
          ),
          text: 'Number Masking',
          path: isFreeFormEnabled
            ? '/number-masking-call-logs'
            : '/number-masking-bindings',
          isEnabled: userRole === 'ADMIN' && isNumberMaskingEnabled,
        },
        callerTunes: {
          iconElement: <SmallMusicIcon type="white" />,
          text: 'Caller Tunes',
          path: '/caller-tunes',
          isEnabled: true,
          // (userRole == 'USER' || !callForwardingStatus == false) &&
          // isCallerTuneEnabled,
        },
        callRouting: {
          iconElement: <RoutingSVG type="white" />,
          text: 'Call Routing',
          path: '/IVR',
          isEnabled: callPriorityRoutingStatus,
        },
        voiceBroadcast: {
          iconElement: <VoiceBroadcastIcon type="white" />,
          text: 'Voice Broadcast',
          path: '/voice-broadcasts-list',
          isEnabled: isVoiceBroadcastEnabled,
        },
        voiceAPI: {
          iconElement: <VoiceBroadcastIcon type="white" />,
          text: 'Voice API',
          path: '/voice-api-list',
          isEnabled: isVoiceApiEnabled,
        },
        messageBroadcast: {
          iconElement: <MessageBroadcastIcon type="white" />,
          text: 'SMS Broadcast',
          path: '/sms-broadcasts-list',
          isEnabled: isMessageBroadcastEnabled,
        },
        webHooks: {
          iconElement: (
            <img
              alt=""
              src={webhookLogo}
              className={`${
                sidebarClosed ? 'Custom-Sidebar-Icon' : 'SidebarItemIcon'
              }`}
            />
          ),
          text: 'WebHooks',
          path:
            // isSMSApiAvailable
            // ? '/sms-webhook'
            isOutgoingApiAvailable
              ? '/outgoing-call-webhook'
              : isCallApiAvailable
              ? '/call-webhook'
              : isVoiceApiAvailable
              ? '/voice-api-webhook'
              : isFreeFormEnabled
              ? '/number-masking-api-webhook'
              : '',
          isEnabled:
            // isSMSApiAvailable ||
            isCallApiAvailable ||
            isOutgoingApiAvailable ||
            isVoiceApiAvailable ||
            isFreeFormEnabled,
        },
        numbers: {
          iconElement: (
            <img alt="" src={keypadWhite} className="SidebarItemIcon" />
          ),
          text: 'Numbers',
          path: '/virtual-numbers',
          isEnabled: true,
        },
        agents: {
          iconElement: (
            <img alt="" src={personWhite} className="SidebarItemIcon" />
          ),
          text: 'Agents',
          path: '/users',
          isEnabled: true,
        },
        contacts: {
          iconElement: (
            <img alt="" src={contactsIcon} className="SidebarItemIcon" />
          ),
          text: 'Contacts',
          path: '/contacts',
          isEnabled: true,
        },
        autoAttendant: {
          iconElement: (
            <img alt="" src={autoAttendantIcon} className="SidebarItemIcon" />
          ),
          text: 'Auto Attendant',
          path: '/auto-attended',
          isEnabled: userRole === 'ADMIN' && isAutoAttendantEnabled == true,
        },
        trailPeriod: {
          iconElement: (
            <img alt="" src={verifiedContact} className="SidebarItemIcon" />
          ),
          text: 'Free trial',
          path: '/trial-period',
          isEnabled: true,
        },
        callFlow: {
          iconElement: (
            <img alt="" src={Callflow} className="SidebarItemIcon" />
          ),
          text: 'Call Flow',
          path: '/call-flows',
          isEnabled: true,
        },
      };

      if (buttonName === 'contacts' && isFreeTrail) {
        return '';
      }
      if (buttonName === 'autoAttendant' && isFreeTrail) {
        return '';
      }
      if (buttonName === 'recoveryX' && !isReocveryXEnabled) {
        return '';
      }
      if (buttonName === 'numberMasking' && !isNumberMaskingEnabled) {
        return '';
      }

      if (buttonName === 'callerTunes' && isFreeTrail) {
        return '';
      }
      if (buttonName === 'webHooks' && isFreeTrail) {
        return '';
      }

      if (buttonName === 'callRouting' && isFreeTrail) {
        return '';
      }

      if (buttonName === 'trailPeriod' && !isFreeTrail) {
        return '';
      }

      const { path, text, iconElement, isEnabled } = buttonConfig[buttonName];
      const content = (
        <React.Fragment>
          {!sidebarClosed && isEnabled && iconElement}
          {sidebarClosed && iconElement}
          {sidebarClosed && <span className="SidebarItemText">{text}</span>}
          {!isEnabled && (
            <span className="Upgrade">
              <img alt="" src={upgradeLockIcon} />
            </span>
          )}
        </React.Fragment>
      );

      const containerClass = isEnabled
        ? 'SidebarItemContainer'
        : 'SidebarItemContainer';

      let link = (
        <Link
          className={`${containerClass} ${
            pathname === path ? 'blueBackGround' : ''
          }`}
          to={path}
        >
          {content}
        </Link>
      );

      if (Array.isArray(path)) {
        for (let p of path) {
          if (pathname === p) {
            link = (
              <Link className={`${containerClass} blueBackGround`} to={p}>
                {content}
              </Link>
            );
            break;
          }
        }

        if (!link) {
          link = (
            <Link className={containerClass} to={path[0]}>
              {content}
            </Link>
          );
        }
      } else {
        link = (
          <Link
            className={`${containerClass} ${
              pathname === path ? 'blueBackGround' : ''
            }`}
            to={path}
          >
            {content}
          </Link>
        );
      }

      return link;
    };

    const settingButton = () => {
      const isAccountSettingsPath = this.state.pathname === '/account-settings';
      const sidebarItemClass = isAccountSettingsPath ? 'blueBackGround' : '';
      if (!isSuperAdminWithPermission('EDIT')) {
        return (
          <div
            className={`SidebarItemContainer ${sidebarItemClass}`}
            to="/account-settings"
          >
            <img alt="" src={settingsWhite} className="SidebarItemIcon" />
            {this.props.sidebarClosed ? (
              <span className="SidebarItemText">Settings</span>
            ) : null}
          </div>
        );
      }
      return (
        <Link
          className={`SidebarItemContainer ${sidebarItemClass}`}
          to="/account-settings"
        >
          <img alt="" src={settingsWhite} className="SidebarItemIcon" />
          {this.props.sidebarClosed ? (
            <span className="SidebarItemText">Settings</span>
          ) : null}
        </Link>
      );
    };

    const { run, stepIndex, steps } = this.props;
    const getRoutesForRole = (role) => {
      if (role === 'ADMIN') {
        return [
          '/trial-period',
          '/call-logs',
          // '/sms-inbox',
          '/virtual-numbers',
          '/users',
        ];
      }
      return [
        '/call-logs',
        // '/sms-inbox',
        '/account-settings',
      ];
    };

    const handleCallback = (data) => {
      console.log('Inside handle call back ', data);
      const { action, index, lifecycle, type } = data;
      const routes = getRoutesForRole(userRole);
      const redirectToRoute = (routeIndex) => {
        if (routeIndex >= 0 && routeIndex < routes.length) {
          this.props.history.push(routes[routeIndex + 1]);
        }
      };

      const resetSteps = () => {
        this.props.EndRun();
        this.props.setStepIndexToZero();
      };

      if (type === 'step:after') {
        switch (action) {
          case 'next':
            this.props.increaseStepIndex();
            if (index === 3 && userRole === 'ADMIN') {
              resetSteps();
              const endRoute =
                isFreeTrail && userRole !== 'ADMIN'
                  ? '/call-logs'
                  : '/trial-period';
              this.props.history.push(endRoute);
            } else if (index === 3 && userRole !== 'ADMIN') {
              resetSteps();
              this.props.history.push('/call-logs');
            } else {
              redirectToRoute(index);
            }
            break;

          case 'prev':
            this.props.decreaseStepIndex();
            if (index === 1) {
              redirectToRoute(index);
            } else {
              redirectToRoute(index - 2);
            }
            break;

          default:
            break;
        }
      } else if (type === 'beacon') {
        this.props.history.push(routes[0]);
      } else if (
        action === 'reset' ||
        lifecycle === 'complete' ||
        action === 'skip'
      ) {
        console.log('calling end run');
        resetSteps();
      }
    };

    return (
      <div className={`${this.props.className}`}>
        {isLoading && <PreLoader />}
        {/* {isFreeTrail && (
          <Joyride
            callback={handleCallback}
            continuous={true}
            disableOverlay={true}
            hideCloseButton
            run={run}
            scrollToFirstStep
            showProgress
            stepIndex={stepIndex}
            showSkipButton
            steps={
              userRole === 'ADMIN'
                ? onbaordingTourConstants.steps[0]
                : onbaordingTourConstants.steps[1]
            }
            styles={{
              options: {
                backgroundColor: 'white', //"#fac596",
                //primaryColor: "#7d34fa",
                textColor: 'black',
                beaconSize: 1,
              },
            }}
          />
        )} */}
        <div
          className={
            this.props.sidebarClosed ? 'Sidebar' : 'Sidebar SidebarHalf'
          }
        >
          {this.props.sidebarClosed ? (
            <div className="SidebarHeadContainer">
              <React.Fragment>
                <img alt="" src={AlohaaLogo} className="SidebarLogo" />

                {/* <div className="SidebarDoosraTitle">alohaa</div> */}

                <img
                  alt=""
                  className="SidebarHeaderBurgerMenu"
                  src={burgerMenu}
                  onClick={this.switchState}
                />
              </React.Fragment>
            </div>
          ) : (
            <div className="SidebarHeadContainerCollapsed">
              <img
                alt=""
                className="SidebarHeaderBurgerMenu"
                src={burgerMenu}
                onClick={this.switchState}
              />
            </div>
          )}
          <div className="SidebarItemsContainer">
            <div className="SidebarTopItems">
              {isFreeTrail && userRole === 'ADMIN' ? (
                <div className="sidebar1">
                  {getSideBarButton('trailPeriod')}
                </div>
              ) : null}
              {userRole === 'USER' &&
                isOutboundCallEnabled &&
                isOrgOutboundCallEnabled &&
                (localStorage.getItem('is_dialer_widget_enabled') === 'false' ||
                  localStorage.getItem('is_dialer_widget_enabled') ===
                    'undefined') && (
                  // !isCloudCallCenterEnabled &&
                  <NumberDialer
                    receiverNo={null}
                    contactId={null}
                    openPopup={false}
                    closePopup={null}
                  />
                )}
              {
                <React.Fragment>
                  <div className="sidebar2">{getSideBarButton('callLogs')}</div>
                </React.Fragment>
              }
              {/* {!isFreeTrail && (
                <React.Fragment>
                  <div className="sidebar3">{getSideBarButton('smsInbox')}</div>
                </React.Fragment>
              )} */}
              {userRole === 'USER' ? null : (
                <div className="sidebar4">{getSideBarButton('numbers')}</div>
              )}
              {userRole === 'USER' ? null : (
                <div className="sidebar5">{getSideBarButton('agents')}</div>
              )}

              <div className="sidebar6">{getSideBarButton('contacts')}</div>

              {<React.Fragment>{getSideBarButton('recoveryX')}</React.Fragment>}
              {userRole === 'USER' ? null : (
                <React.Fragment>
                  {getSideBarButton('callerTunes')}
                </React.Fragment>
              )}
              {userRole == 'USER' ? null : (
                <React.Fragment>
                  {getSideBarButton('callRouting')}
                </React.Fragment>
              )}
              {userRole === 'ADMIN' &&
              (this.state.isCallApiAvailable ||
                this.state.isOutgoingApiAvailable ||
                this.state.isVoiceApiAvailable ||
                isFreeFormEnabled) ? (
                <React.Fragment>{getSideBarButton('webHooks')}</React.Fragment>
              ) : null}
              {userRole === 'ADMIN' ? (
                <React.Fragment>
                  {getSideBarButton('autoAttendant')}
                </React.Fragment>
              ) : null}
              {this.props.isVoiceBroadcastEnabled && userRole === 'ADMIN' && (
                <React.Fragment>
                  {getSideBarButton('voiceBroadcast')}
                </React.Fragment>
              )}
              {this.props.isVoiceApiEnabled && userRole === 'ADMIN' && (
                <React.Fragment>{getSideBarButton('voiceAPI')}</React.Fragment>
              )}
              {this.props.isMessageBroadcastEnabled && userRole === 'ADMIN' && (
                <React.Fragment>
                  {getSideBarButton('messageBroadcast')}
                </React.Fragment>
              )}

              {isCloudCallCenterEnabled === true && userRole === 'ADMIN' && (
                <React.Fragment>
                  {this.state.pathname !== '/dashboard' ? (
                    <Link className="SidebarItemContainer" to="/dashboard">
                      {this.props.sidebarClosed ? (
                        <React.Fragment>
                          <img
                            alt=""
                            src={bullsEye}
                            className="SidebarItemIcon"
                          />
                          <span className="SidebarItemText">Call Center</span>
                        </React.Fragment>
                      ) : (
                        <img
                          alt=""
                          src={bullsEye}
                          className="SidebarItemIcon"
                        />
                      )}
                    </Link>
                  ) : (
                    <Link
                      className="SidebarItemContainer blueBackGround"
                      to="/dashboard"
                    >
                      {this.props.sidebarClosed ? (
                        <React.Fragment>
                          <img
                            alt=""
                            src={bullsEye}
                            className="SidebarItemIcon"
                          />
                          <span className="SidebarItemText">Call Center</span>
                        </React.Fragment>
                      ) : (
                        <img
                          alt=""
                          src={bullsEye}
                          className="SidebarItemIcon"
                        />
                      )}
                    </Link>
                  )}
                </React.Fragment>
              )}

              {localStorage.getItem('is_analytics_enabled') === 'true' &&
                userRole === 'ADMIN' && (
                  <React.Fragment>
                    {this.state.pathname !== '/analytics' ? (
                      <Link className="SidebarItemContainer" to="/analytics">
                        {this.props.sidebarClosed ? (
                          <React.Fragment>
                            <img
                              alt=""
                              src={analytics}
                              className="SidebarItemIcon"
                            />
                            <span className="SidebarItemText">Analytics</span>
                          </React.Fragment>
                        ) : (
                          <img
                            alt=""
                            src={analytics}
                            className="SidebarItemIcon"
                          />
                        )}
                      </Link>
                    ) : (
                      <Link
                        className="SidebarItemContainer blueBackGround"
                        to="/analytics"
                      >
                        {this.props.sidebarClosed ? (
                          <React.Fragment>
                            <img
                              alt=""
                              src={analytics}
                              className="SidebarItemIcon"
                            />
                            <span className="SidebarItemText">Analytics</span>
                          </React.Fragment>
                        ) : (
                          <img
                            alt=""
                            src={analytics}
                            className="SidebarItemIcon"
                          />
                        )}
                      </Link>
                    )}
                  </React.Fragment>
                )}

              {localStorage.getItem('is_campaigns_v2_enabled') === 'true' &&
                userRole === 'ADMIN' && (
                  <React.Fragment>
                    {this.state.pathname !== '/campaigns-v2' ? (
                      <Link className="SidebarItemContainer" to="/campaigns-v2">
                        {this.props.sidebarClosed ? (
                          <React.Fragment>
                            <img
                              alt=""
                              src={campaign}
                              className="SidebarItemIcon"
                            />
                            <span className="SidebarItemText">Campaigns</span>
                          </React.Fragment>
                        ) : (
                          <img
                            alt=""
                            src={campaign}
                            className="SidebarItemIcon"
                          />
                        )}
                      </Link>
                    ) : (
                      <Link
                        className="SidebarItemContainer blueBackGround"
                        to="/campaigns-v2"
                      >
                        {this.props.sidebarClosed ? (
                          <React.Fragment>
                            <img
                              alt=""
                              src={campaign}
                              className="SidebarItemIcon"
                            />
                            <span className="SidebarItemText">Campaigns</span>
                          </React.Fragment>
                        ) : (
                          <img
                            alt=""
                            src={campaign}
                            className="SidebarItemIcon"
                          />
                        )}
                      </Link>
                    )}
                  </React.Fragment>
                )}

              {isCampaignEnabled === true && userRole === 'ADMIN' && (
                <React.Fragment>
                  {this.state.pathname !== '/recovery-x-dashboard' ? (
                    <Link
                      className="SidebarItemContainer"
                      to="/recovery-x-dashboard"
                    >
                      {this.props.sidebarClosed ? (
                        <React.Fragment>
                          <img
                            alt=""
                            src={bullsEye}
                            className="SidebarItemIcon"
                          />
                          <span className="SidebarItemText">RecoveryX</span>
                        </React.Fragment>
                      ) : (
                        <img
                          alt=""
                          src={bullsEye}
                          className="SidebarItemIcon"
                        />
                      )}
                    </Link>
                  ) : (
                    <Link
                      className="SidebarItemContainer blueBackGround"
                      to="/recovery-x-dashboard"
                    >
                      {this.props.sidebarClosed ? (
                        <React.Fragment>
                          <img
                            alt=""
                            src={bullsEye}
                            className="SidebarItemIcon"
                          />
                          <span className="SidebarItemText">RecoveryX</span>
                        </React.Fragment>
                      ) : (
                        <img
                          alt=""
                          src={bullsEye}
                          className="SidebarItemIcon"
                        />
                      )}
                    </Link>
                  )}
                </React.Fragment>
              )}

              {isCampaignEnabled === true && userRole === 'USER' && (
                <React.Fragment>
                  {this.state.pathname !== '/campaigns' ? (
                    <Link className="SidebarItemContainer" to="/campaigns">
                      {this.props.sidebarClosed ? (
                        <React.Fragment>
                          <img
                            alt=""
                            src={bullsEye}
                            className="SidebarItemIcon"
                          />
                          {/* <GiCampingTent size={25} /> */}
                          <span className="SidebarItemText">RecoveryX</span>
                        </React.Fragment>
                      ) : (
                        <img
                          alt=""
                          src={bullsEye}
                          className="SidebarItemIcon"
                        />
                        // <GiCampingTent size={25} />
                      )}
                    </Link>
                  ) : (
                    <Link
                      className="SidebarItemContainer blueBackGround"
                      to="/campaigns"
                    >
                      {this.props.sidebarClosed ? (
                        <React.Fragment>
                          <img
                            alt=""
                            src={bullsEye}
                            className="SidebarItemIcon"
                          />
                          {/* <GiCampingTent size={25} /> */}
                          <span className="SidebarItemText">RecoveryX</span>
                        </React.Fragment>
                      ) : (
                        <img
                          alt=""
                          src={bullsEye}
                          className="SidebarItemIcon"
                        />
                        // <GiCampingTent size={25} />
                      )}
                    </Link>
                  )}
                </React.Fragment>
              )}

              {localStorage.getItem('is_campaigns_v2_enabled') === 'true' &&
                userRole === 'USER' && (
                  <React.Fragment>
                    {this.state.pathname !== '/calls-v2' ? (
                      <Link className="SidebarItemContainer" to="/calls-v2">
                        {this.props.sidebarClosed ? (
                          <React.Fragment>
                            <img
                              alt=""
                              src={campaign}
                              className="SidebarItemIcon"
                            />
                            {/* <GiCampingTent size={25} /> */}
                            <span className="SidebarItemText">Campaigns</span>
                          </React.Fragment>
                        ) : (
                          <img
                            alt=""
                            src={campaign}
                            className="SidebarItemIcon"
                          />
                          // <GiCampingTent size={25} />
                        )}
                      </Link>
                    ) : (
                      <Link
                        className="SidebarItemContainer blueBackGround"
                        to="/calls-v2"
                      >
                        {this.props.sidebarClosed ? (
                          <React.Fragment>
                            <img
                              alt=""
                              src={campaign}
                              className="SidebarItemIcon"
                            />
                            {/* <GiCampingTent size={25} /> */}
                            <span className="SidebarItemText">Campaigns</span>
                          </React.Fragment>
                        ) : (
                          <img
                            alt=""
                            src={campaign}
                            className="SidebarItemIcon"
                          />
                          // <GiCampingTent size={25} />
                        )}
                      </Link>
                    )}
                  </React.Fragment>
                )}

              {userRole === 'ADMIN' ? (
                <React.Fragment>
                  {getSideBarButton('numberMasking')}
                </React.Fragment>
              ) : null}
            </div>
            <div className="SidebarBottomItems">
              {userRole == 'USER' &&
              callForwardingStatus == true &&
              this.state.callForwardingAllowed &&
              localStorage.getItem('is_dialer_widget_enabled') !== 'true' ? (
                <React.Fragment>
                  <div
                    style={{
                      height: '54px',
                      border: 'solid 1px #ffffff',
                      borderRadius: '4px',
                      margin: '8px',
                      padding: '16px',
                      display: 'grid',
                      gridTemplateColumns: '15% 75% 10%',
                      whiteSpace: 'nowrap',
                      alignContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    {this.props.sidebarClosed ? (
                      <React.Fragment>
                        <img
                          src={phoneForward}
                          style={{ width: '20px', padding: '3px 2px' }}
                        />
                        <p
                          style={{
                            color: '#ffffff',
                            fontWeight: '600',
                            fontSize: '15px',
                            margin: 0,
                          }}
                        >
                          Availability
                        </p>

                        {this.state.callForwarding == true ? (
                          <img
                            style={{
                              width: '29px',
                              height: '16px',
                              // marginTop: '6px',
                              cursor: 'pointer',
                            }}
                            src={toggleOn}
                            onClick={() =>
                              this.callForwardingStateHandler({
                                is_call_forwarding: false,
                              })
                            }
                          />
                        ) : (
                          <img
                            style={{
                              width: '29px',
                              height: '16px',
                              marginTop: '6px',
                              cursor: 'pointer',
                            }}
                            src={toggleOff}
                            onClick={() =>
                              this.callForwardingStateHandler({
                                is_call_forwarding: true,
                              })
                            }
                          />
                        )}
                      </React.Fragment>
                    ) : (
                      <img
                        src={phoneForward}
                        style={{ paddingLeft: '2px', width: '20px' }}
                      />
                    )}
                  </div>
                </React.Fragment>
              ) : null}
              {userRole !== 'USER' && (
                <div
                  className="SidebarItemContainer"
                  onClick={() => {
                    window.open('https://docs.alohaa.ai/', '_blank');
                  }}
                >
                  {this.props.sidebarClosed ? (
                    <React.Fragment>
                      <img alt="" src={codeIcon} className="SidebarItemIcon" />
                      <span className="SidebarItemText">API doc</span>
                    </React.Fragment>
                  ) : (
                    <img alt="" src={codeIcon} className="SidebarItemIcon" />
                  )}
                </div>
              )}
              <div className="sidebar7">{settingButton()}</div>
              {userRole !== 'USER' && (
                <div className="sidebar6">{getSideBarButton('callFlow')}</div>
              )}
              <div
                className="SidebarItemContainer"
                onClick={this.logoutHandler}
              >
                {this.props.sidebarClosed ? (
                  <React.Fragment>
                    <img alt="" src={logoutWhite} className="SidebarItemIcon" />
                    <span className="SidebarItemText">Logout</span>
                  </React.Fragment>
                ) : (
                  <img alt="" src={logoutWhite} className="SidebarItemIcon" />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  sidebarClosed: state.sidebar.sidebarClosed,
  callForwardingStatus: state.topbar.call_forwarding_status,
  callPriorityRoutingStatus: state.topbar.call_priority_routing,
  isOrgOutboundCallEnabled: state.topbar.isOrgOutboundCallEnabled,
  initCallPopup: state.topbar.initCallPopup,
  isCallerTuneEnabled: state.topbar.isCallerTuneEnabled,
  isReocveryXEnabled: state.topbar.isReocveryXEnabled,
  isNumberMaskingEnabled: state.topbar.isNumberMaskingEnabled,
  isSmsInboxEnabled: state.topbar.isSmsInboxEnabled,
  isAutoAttendantEnabled: state.topbar.isAutoAttendantEnabled,
  isCampaignEnabled: state.topbar.isCampaignEnabled,
  orgName: state.topbar.name,
  accessKeyForSMSWebhook: state.sidebar.accessKeyForSMSWebhook,
  accessKeyForVoiceApiWebhook: state.sidebar.accessKeyForVoiceApiWebhook,
  accessKeyForNumberMaskingWebhook:
    state.sidebar.accessKeyForNumberMaskingWebhook,
  accessKeyForNumberMaskingCallDetailsWebhook:
    state.sidebar.accessKeyForNumberMaskingCallDetailsWebhook,
  accessKeyForCallWebhook: state.sidebar.accessKeyForCallWebhook,
  isMessageBroadcastEnabled: state.topbar.isMessageBroadcastEnabled,
  isVoiceBroadcastEnabled: state.topbar.isVoiceBroadcastEnabled,
  isVoiceApiEnabled: state.topbar.isVoiceApiEnabled,
  run: state.onboardingtour?.run,
  stepIndex: state.onboardingtour?.stepIndex,
  isCloudCallCenterEnabled: state.topbar.isCCOutgoingCallsEnabled,
  steps: state.onboardingtour?.steps,
  tourActive: state.onboardingtour?.tourActive,
  isFreeFormEnabled: state.topbar.isFreeFormEnabled,
  batchId: state.dailpad.batchId,
  selectedCampaign: state.dailpad.selectedCampaign,
  RTCSessionData: state.dailpad.RTCSessionData,
});

export default styled(
  withRouter(
    connect(mapStateToProps, {
      toggleSidebar,
      saveUserProfileDetails,
      setSMSAccessKey,
      setCallAccessKey,
      increaseStepIndex,
      decreaseStepIndex,
      setStepIndexToZero,
      startRun,
      EndRun,
    })(Sidebar)
  )
)`
  .ParentContainer {
    display: grid;
    grid-template-columns: 384px auto;
  }

  .Sidebar {
    margin: 0;
    padding: 0;
    width: 230px;
    background-color: ${(props) => props.theme.colors.sidebarBgColor};
    position: fixed;
    height: -webkit-fill-available;
    overflow: auto;
    transition: width 0.3s;
  }

  .SidebarHalf {
    margin: 0;
    padding: 0;
    width: 52px;
    background-color: ${(props) => props.theme.colors.sidebarBgColor};
    position: fixed;
    height: 100%;
    overflow: auto;
    transition: width 0.3s;
  }

  .Sidebar a.active {
    background-color: #fffff;
    color: #000000;
  }

  .SidebarHeadContainer {
    /* margin: 0 0 16px; */
    padding: 13px 16px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    display: grid;
    grid-template-columns: auto 45px;
    background: ${(props) => props.theme.colors.sidebarHeaderBgColor};
    align-items: center;
  }

  .SidebarHeadContainerCollapsed {
    height: 72px;
    /* margin: 0 0 16px; */
    padding: 13px 16px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  }

  .SidebarLogo {
    float: left;
    padding-left: 10px;
    height: 26px;
  }

  .SidebarDoosraTitle {
    font-size: 24px;
    font-weight: bold;
    margin-left: 14px;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;

    color: var(--white-color);
  }

  .SidebarBusinessSubTitle {
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: var(--bluewish-blue-color);
  }

  .SidebarHeaderBurgerMenu {
    float: right;
    margin: 16px 8px 16px 16px;
  }

  .SidebarItemsContainer {
    // height: 100%;
    height: calc(100vh - 100px);
    // height: inherit;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  /* .SidebarSpacing {
  margin-top: calc(100vh - 464px);
} */

  .Content {
    margin-left: 230px;
    height: 100vh;
    transition: margin 0.3s;
  }

  .ContentFull {
    margin-left: 72px;
    height: 100vh;
    transition: margin 0.3s;
  }

  .SidebarItemContainer {
    position: relative;
    height: 54px;
    display: block;
    padding: 16px;
    text-decoration: none;
    background-color: rgba(255, 255, 255, 0.2);
    color: var(--white-color);
    margin: 8px;
    border-radius: 4px;
    white-space: nowrap;
    text-overflow: ellipsis;
    cursor: pointer;
  }

  .SidebarItemDisabledContainer {
    display: block;
    padding: 16px;
    text-decoration: none;
    background-color: ${(props) => props.theme.colors.hoverColor};
    color: var(--white-color);
    margin: 8px;
    border-radius: 4px;
    max-height: 54px;
    /* for making text smooth */
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .SidebarItemContainer:hover {
    background-color: ${(props) => props.theme.colors.hoverColor};
    text-decoration: none;
  }

  .SidebarItemContainerTextWrap {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .WhiteBackground {
    background-color: var(--white-color);
  }

  .blueBackGround {
    background-color: ${(props) => props.theme.colors.primaryColor1};
  }
  .SidebarItemIcon {
    margin-right: 4px;
    margin-left: 4px;
    width: 16px;
    margin-bottom: 3px;
    cursor: pointer;
  }

  .SidebarItemText {
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: var(--light-grey-color);
    padding-left: 10px;
  }

  .Upgrade {
    position: absolute;
    top: 50%;
    right: 0;
    width: 46px;
    text-align: center;
    transform: translateY(-50%);
    margin-right: 7px;
  }

  .PrimaryColor {
    color: #33aabb;
  }

  .SecondaryColor {
    color: var(--secondary-color1);
  }

  .SidebarLogoutElement {
    position: absolute;
    width: 170px;
    bottom: 20px;
  }

  .SidebarAccountSetingElement {
    position: absolute;
    width: 170px;
    bottom: 90px;
  }

  @media only screen and (max-width: 900px) {
    .Sidebar {
      margin: 0;
      padding: 0;
      width: -webkit-fill-available;
      background-color: var(--secondary-color1);
      position: fixed;
      height: 100;
      overflow: auto;
      transition: none;
      z-index: 5;
    }
    .SidebarHalf {
      margin: 0;
      padding: 0;
      width: 100%;
      background-color: var(--secondary-color1);
      position: fixed;
      height: 74px;
      overflow: auto;
      transition: none;
      z-index: 5;
    }
    .Content {
      margin-left: 0px;
      padding-top: 74px;
      height: auto;
      width: 100%;
      transition: none;
    }
    .ContentFull {
      margin-left: 0px;
      padding-top: 74px;
      height: auto;
      width: 100%;
      transition: none;
    }
  }
`;
